// TrustedClient.js
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import OIDImage1 from '../../assets/images/ImageA.png';
import OIDImage3 from '../../assets/images/ImageC.png';
import OIDImage4 from '../../assets/images/imageD.png';
import OIDImage5 from '../../assets/images/imageE.png';
import OIDImage6 from '../../assets/images/imageF.png';
import OIDImage7 from '../../assets/images/imageG.png';
import OIDImage8 from '../../assets/images/imageH.png';
import OIDImage9 from '../../assets/images/imageI.png';
import OIDImage10 from '../../assets/images/imageJ.png';
import OIDImage11 from '../../assets/images/imageK.svg';
import '../../assets/styles/TrustedClient.css';

const TrustedClient = () => {
  const images = [
    { src: OIDImage1, alt: 'Image 1' },
    { src: OIDImage3, alt: 'Image 3' },
    { src: OIDImage4, alt: 'Image 4' },
    { src: OIDImage5, alt: 'Image 5' },
    { src: OIDImage6, alt: 'Image 6' },
    { src: OIDImage7, alt: 'Image 7' },
    { src: OIDImage8, alt: 'Image 8' },
    { src: OIDImage9, alt: 'Image 8' },
    { src: OIDImage10, alt: 'Image 8' },
    { src: OIDImage11, alt: 'Image 8' }
  ];

  const sliderSettings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 0, // Set to 0 for continuous scrolling
    cssEase: 'linear',
    variableWidth: true,
    pauseOnHover: false,
    pauseOnFocus: false,
    arrows: false, // Remove slick arrows
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="trusted-clients-section">
      <div className="section-header text-center mb-4">
        <h1 className="black-text">
          Our Trusted <span className="gradient-text">Clients</span>
        </h1>
        <p className="subtext mb-4 mb-md-0" style={{ wordBreak: 'break-word', marginTop: '6%' }}>
          Trusted by Over 10,000 Clients – From Individuals to Groups and Businesses
        </p>
      </div>

      <Slider {...sliderSettings}>
        {images.map((image, index) => (
          <div key={index} className="image-container">
            <img
              src={image.src}
              alt={image.alt}
              className="img-fluid client-image"
            />
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default TrustedClient;

/* TrustedClient.css */

/* Remove the slick-arrow styling since arrows are removed */
