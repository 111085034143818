// App.js
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Home from './components/home';
import AboutUs from './components/AboutUs/index';
import JoinFarmsellPage from './components/JoinFarmsell/page';
import Registration from './components/Registration/Registration';
import WelcomePage from './components/Registration/Welcome';
import {GoogleOAuthProvider} from '@react-oauth/google';
import TermsOfUser from './components/FarmsellLegal/TermsOfUser';
import PrivacyPolicy from './components/FarmsellLegal/PrivacyPolicy';
import {CommunityHome} from './components/CommunityPage/home';
import HowItWorks from './components/HowItWorks/howitworks';
import ContactUs from './components/ContactUs';

function App () {
  return (
    <GoogleOAuthProvider clientId="1056600415170-9cn2lo9gm3hftp21eono93i9sij75eg3.apps.googleusercontent.com">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/HowItWorks" element={<HowItWorks />} />

          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />

          <Route path="/join-farmsell" element={<JoinFarmsellPage />} />
          <Route path="/registration/:name" element={<Registration />} />
          <Route path="/welcome" element={<WelcomePage />} />
          <Route path="/terms" element={<TermsOfUser />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/community" element={<CommunityHome />} />
          {/* <Route path="/reg" element={<div>test</div>} /> */}

        </Routes>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
}

export default App;
