import React from 'react';
import Header from '../Header/index';
import Footer from '../Footer';
import HeroSection from '../HeroSection/index';
import WhoAreWe from '../WhoWeAre/index';
import MeetTheVisionary from '../MeetTheVisionary/index';
import VisionAndMission from '../VisionAndMission';
import FundingPriciple from './FundingPriciple';
import CoreValues from './CoreValues';
import OurAchivements from './OurAchivements';
import StrategicComponent from './StrategicComponent';
import OurApproach from './OurApproach';
import ElevateYour from './ElavetYour';
import TestimonialPage from '../Testimonies';
import JoinFarmsell from '../JoinFarmsell/joinFarmsell';

const AboutUs = () => {
  return (
    <div style={{overflow: 'hidden'}}>
      <Header />
      <HeroSection />
      <WhoAreWe />
      <MeetTheVisionary />
      <FundingPriciple />
      <ElevateYour />
      <VisionAndMission />
      <CoreValues />
      <OurAchivements />
      <OurApproach />
      <StrategicComponent />
      <TestimonialPage />
      <JoinFarmsell />

      <Footer />
    </div>
  );
};

export default AboutUs;
