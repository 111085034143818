import React, { useState, useEffect } from 'react';
import '../../assets/styles/heroSection.css';
import HeroImgae from  '../../assets/images/image land.svg';
import GradientText from './GradientText';

const HeroSection = () => {
  const originalText = "Connect with Farmers";
  const replacementTexts = [
    { text: "Hotels", gradient: "linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113))" },
    { text: "Supermarkets", gradient: "linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113))" },
    { text: "Agro-Input Enterprises", gradient: "linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113))" },
    { text: "Agro-Based Industries", gradient: "linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113))" },
    { text: "Smallholder Farmers", gradient: "linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113))" },
  ];
  const [modifiedText, setModifiedText] = useState(originalText);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const randomReplacement = replacementTexts[Math.floor(Math.random() * replacementTexts.length)];
      setModifiedText(randomReplacement);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [replacementTexts]);

  return (
    <div>
      <div className='hero-section'>
        <div className='hero-content'>
          <div className='hero-texts'>
            <h2 className='hero-h'>Revolutionize Agricultural trade with <span className='hero-span'>Farmsell </span> – Your Trusted <span className='hero-span'>B2B 
            Marketplace </span></h2>
            <p className='hero-p'>
              Connect with <GradientText text={modifiedText.text} gradient={modifiedText.gradient} />
            </p>
            <p className='hero-pdown'>to maximize your profitability</p>
          </div>

          <div className='hero-image'>
            <img className='hero-images' src={HeroImgae} alt="Hero" />
          </div>
          
        
        </div>
      </div>
    </div>
  );
};
export default HeroSection
