import Logo from '../../assets/images/Logo Farmsell.svg';
import Apple from '../../assets/images/Apple.svg';
import Google from '../../assets/images/Google Play.svg';
import LinkList from './linkList';

import {Link} from 'react-router-dom';
import navigateToNewTab from '../../shared/newTabRedirect';

const FirstFooter = () => {
  const redirectToStore = url => {
    navigateToNewTab (url);
  };

  return (
    <div className="firstFooterContainer">

      <div className="img-container">
        <Link to="/">
          <img src={Logo} alt="Farmsell Logo" className="img-logo" />
        </Link>
      </div>
      <LinkList />

      <div className="download-container">
        <h4 className="download-header">Download Farmsell app now</h4>
        <div style={{marginTop: -8, fontSize: 15, color: 'white'}}>
          Join Farmsell and be part of a transformative journey
        </div>

        <div
          className="ios-download"
          style={{cursor: 'pointer'}}
          onClick={() =>
            redirectToStore (
              'https://apps.apple.com/ug/app/farmsell/id6447581635'
            )}
        >
          <img
            style={{marginLeft: '-15%'}}
            src={Apple}
            alt="Farmsell IOS Icon"
          />
          <div style={{marginLeft: '5%'}}> Download for iOS</div>
        </div>

        <div
          className="android-download"
          onClick={() =>
            redirectToStore (
              'https://play.google.com/store/apps/details?id=com.farmsell&hl=en&gl=US'
            )}
          style={{cursor: 'pointer'}}
        >
          <img src={Google} alt="Farmsell IOS Icon" />
          <div style={{color: 'white', whiteSpace: 'nowrap', marginLeft: '2%'}}>
            Download for Android
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstFooter;
