import React from 'react'
import '../../assets/styles/header.css';
import {useState} from 'react';
import Logo from '../../assets/images/Header.svg';
import HamburgerIcon from '../../assets/images/Menu.svg';
import Exi from '../../assets/images/exi.svg';
import {useNavigate} from 'react-router';
import Sidebar from '../HarmbugMenu';
export default function CommunityHeader() {
    const [menuOpen, setMenuOpen] = useState (false);
  const navigate = useNavigate ();

  const redirectToPage = redirect => {
    navigate (redirect);
  
  };
  const How = () => {
    navigate ('/HowItWorks');
  };
  
  const toggleMenu = () => {
    setMenuOpen (!menuOpen);
  };
  return (
    
      <div className="our-header">

<Sidebar isMenu={menuOpen} setMenu={() => toggleMenu ()} />
<header className="main-header shadow header-main-web">
  <div>
    <img
      src={Logo}
      alt="logo"
      className="logo"
      style={{width: '140px', cursor: 'pointer'}}
      
    />
    <nav className="nav" style={{marginLeft: '99px'}}>
      <ul>
        <li >Home</li>
      <li>Webinars</li>


        <li >Seminars</li>
        <li >Trade Shows</li>

      </ul>
    </nav>
    <button
      className="join-btn web"
    
    >
      farmsell.org
    </button>
    <button className="join-btn mobile">farmsell.org</button>
    <img
      src={HamburgerIcon}
      alt="hamburger"
      className="hamburger"
      onClick={toggleMenu}
      style={{cursor: 'pointer'}}
    />
  </div>
</header>

<header className="main-header shadow header-main-mobile">
  <div className="header-mobile-home-container">
    <img
      src={Logo}
      alt="logo"
      className="logo"
      style={{width: '140px'}}
    />

    <button
      className="join-btn mobile"
      style={{marginLeft: 'auto'}}
      onClick={() => redirectToPage ('/join-farmsell')}
    >
     farmsell.org
    </button>
    <img
      src={HamburgerIcon}
      alt="hamburger"
      style={{height: 18, marginLeft: 8}}
      onClick={toggleMenu}
    />
  </div>
</header>
    </div>
  )
}
