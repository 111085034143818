import { Link } from "react-router-dom";


const LinkList = () => {
  return (
    <div className="linkedListContainer">
      <div className="footerLinks">
        <ul>
          <li style={{color: '#F7B618', fontWeight: '600', fontSize: 20}}>
            About us
          </li>
          <li><Link to = "/AboutUs">Founding Story</Link></li>
          <li><Link to = "/AboutUs">Core Principles</Link></li>
          <li><Link to = "/AboutUs">Vision & Mission</Link></li>
          <li><Link to = "/AboutUs">Core Values</Link></li>
          <li><Link to = "/AboutUs">Our Approach</Link></li>
          <li><Link to = "/AboutUs">Partnerships</Link></li>

        </ul>

        <ul>
          <li style={{color: '#F7B618', fontWeight: '600', fontSize: 20}}>
          Useful Links
          </li>
          <li style={{display:'none'}}><a href="/core-values.html">Blog</a></li>
          <li><Link  to="/contact-us">Contact Us</Link></li>
          <li><Link  to="/HowItWorks">How We Work</Link></li>
          <li style={{display:'none'}}><a href="/core-values.html">Help center</a></li>
          <li ><Link to="/terms">Terms of Use</Link></li>
          <li><Link  to="/privacy">Privacy Policy</Link></li>

        </ul>
        <ul>
          <li style={{color: '#F7B618', fontWeight: '600', fontSize: 20}}>
          Community
          </li>
          <li><Link to="/community">Forums</Link></li>
          <li><Link to="/community">Webinars</Link></li>
          <li><Link to="/community">Workshops</Link></li>
          <li><Link to="/community">Trade shows</Link></li>
     

        </ul>
      </div>

    </div>
  );
};

export default LinkList;
