import React from 'react'
import '../../assets/styles/howitWorks2.css';


const StepsComponent = ({ imageSrc, title, sub_title, description }) => {

  return (
    <div>
      <div className="step-container">
        <div className="step-image-container">
          <img className='steps-images' src={imageSrc} alt="Step" />
        </div>
        <div className="text-container">
        <div className="circle-t">
          <div className="circle">
          <div className='vertical-line'></div>
          </div>
        <h1 className='steps-title'>{title}</h1>
        </div>
          
          <h2 className='steps-sub-t'>{sub_title}</h2>
          <p className='steps-p'>{description}</p>
        </div>
      </div>
    </div>
  )
}

export default StepsComponent

