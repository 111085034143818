import React, {useEffect} from 'react';
import Footer from './Footer';
import WhyFarmSell from './WhyFarmsell/WhyFarmsell';
import HeroSection from './Revolutionizing/HeroSection';
import BannerGetStarted from './GetStarted/BannerGetStarted';
// import Header from './Header';
import OurServices from './OurServices/ourServices';
import TailoredBenefits from './TailoredBenefits/tailoredBenefits';
import HowItWorks from './HowFarmsellWorks/HowItWorks';
import JoinFarmsell from './JoinFarmsell/joinFarmsell';
import TrustedClient from './TrustedClient/trustedClient';
import WelcomeToFarmsell from './WelcomeFarmsell/welcomeToFarmsell';

// import BurgerMenu from '../components/Header/Burger/BurgerMenu';
import Header from './Header';
import TestimonialPage from './Testimonies';
import navigateToTopPage from '../shared/navigateTop';
const Home = () => {
  useEffect (() => {
    navigateToTopPage ();
  }, []);

  return (
    <div style={{overflowX: 'hidden'}}>
      {/* <BurgerMenu />
            <Header /> */}
      <Header />
      <HeroSection />
      <TrustedClient />
      <WelcomeToFarmsell />
      <WhyFarmSell />
      <OurServices />
      <TailoredBenefits />
      <BannerGetStarted />
      <HowItWorks />

      <TestimonialPage />
      <JoinFarmsell />

      <Footer />
    </div>
  );
};
export default Home;
