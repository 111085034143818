import React from 'react'
import forumImage from '../../assets/images/Group.svg'
import ButtonArrow from '../../assets/images/welcomebuttonarrow.svg';
import '../../assets/styles/Forums.css'
export default function Forums() {
  return (
    <div className='f-c'>


      <div className='f-wrapper'>

        <div className='f-header'>
          <h2 className='heading'>
            <span className='bg-text'>Forums</span><br />
            <span style={{ color: "#274C5B" }}> Where Minds Cultivate Ideas</span>
          </h2>


        </div>
        <div className='f-w'>
          <div className='f-img'>
            <img className='f-images' src={forumImage} alt='forumImage' />
          </div>
          <div className='f-text'>
            <div className='f-para'>  <p className='f-p'>
              Step into our vibrant forums, where the heartbeat of the agricultural community resonates. Tailored to the unique needs of smallholder farmers, agri-input enterprises, and agro-industries, these forums are hubs of collaboration, innovation, and shared wisdom. Join like-minded individuals, share your experiences, exchange valuable insights, and grow together. Click now to be a part of the conversation and elevate your agricultural journey.

            </p></div>
            <div className='f-btnW'> <button
              className='f-btn'

            >
              Join the Forums Now
              <span className=''>
                <img src={ButtonArrow} alt='buttonarrow' />
              </span>
            </button></div>
          </div>

        </div>
      </div>
    </div>
  )
}
