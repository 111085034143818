import React from "react";
import MessageIcon from "../../assets/images/contactus/messageicon.svg";
import PhoneIcon from "../../assets/images/contactus/phoneicon.svg";
import LinkedIn from "../../assets/images/contactus/Linkedin.svg";
import FaceBook from "../../assets/images/contactus/facebook.svg";
import YouTube from "../../assets/images/contactus/Youtube.svg";
import Exi from "../../assets/images/contactus/exi.svg";
import Arrow from "../../assets/images/welcomebuttonarrow.svg";
import Location from "../../assets/images/contactus/location.svg";


const GetinTouch = () => {
    return (
        <div className="get-container">
            <div className="sect-one">
                <div className="section-content">
                    <h3>Get in <span>touch</span> with us</h3>
                    <div className="desc-text-sect1">
                        Do you have questions or ideas?
                        We'd love to hear from you
                        so that we can personalize
                        the right experience for you.
                    </div>
                    <div className="items">
                        <div className="item">
                            <div className="icon">
                                <img src={MessageIcon} alt="message icon" />
                            </div>
                            <div className="name">Mail Us</div>
                            <div className="src">sell@farmsell.org</div>
                        </div>
                        <div className="item">
                            <div className="icon">
                                <img src={PhoneIcon} alt="phone icon" />
                            </div>
                            <div className="name">Call Us</div>
                            <div className="src">+256200908088</div>
                        </div>
                        <div className="item last">
                            <div className="icon">
                                <img src={Location} alt="location icon" />

                            </div>
                            <div className="name">Visit Us</div>
                            <div className="src">Plot 280, Block 244. <br />Bujjuko, Wakiso District</div>
                        </div>

                        <div className="social-media-icons">
                            <ul>
                                <li>
                                    <img src={LinkedIn} alt="linkein" />
                                </li>
                                <li>
                                    <img src={YouTube} alt="youtub" />
                                </li>
                                <li>
                                    <img src={FaceBook} alt="fcebook" />
                                </li>
                                <li>
                                    <img src={Exi} alt="exi" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sect-two">
                <div className="cont-form">
                    <div className="element">
                        <label>Your Name*</label>
                        <div>
                            <input type="text" className="one" placeholder="First Name" />
                            <input type="text" className="two" placeholder="Second name" />
                        </div>
                    </div>
                    <div className="element">
                        <label>Your Email*</label>
                        <div>
                            <input type="email" placeholder="example@gmail.com" />
                        </div>
                    </div>
                    <div className="element">
                        <label>Phone Number*</label>
                        <div>
                            <input type="tel" placeholder="+245  000 0000  00000" />
                        </div>
                    </div>
                    <div className="element">
                        <label>Subject</label>
                        <div>
                            <input type="text" placeholder="How can we help you" />
                        </div>
                    </div>
                    <div className="element">
                        <label>Message</label>
                        <div>
                            <textarea placeholder="Hello there,I would like to talk about how to..."></textarea>
                        </div>
                    </div>
                    <div className="send-button">
                        <button>Submit <img src={Arrow} alt="arrow" /></button>
                    </div>

                </div>
            </div>

        </div>

    );
};

export default GetinTouch;