import React from 'react'
import '../../assets/styles/Comunity.css'
import communityImage from '../../assets/images/community.svg'
export default function Community() {
  return (
    <div className='c-c'>


        <div className='c-w'>
            <div className='c-img'>
                <img className='c-images' src={communityImage} alt=''/>
            </div>
            <div className='c-text'>
               <div className='c-heading'> <h2 className='c-h'>
                Farmsell: A Community, A Movement

                </h2></div>
             <div className='c-para'>   <p className='c-p'>
                Farmsell is more than a B2B mobile app; it's a community-driven movement that transcends boundaries. Join us in revolutionizing agriculture, one connection at a time. Download Farmsell from the Apple App Store or Google Play Store and be part of a transformative experience that fosters efficiency, reduces losses, and contributes to the improvement of food security and income for millions.
                </p>
</div>

            </div>
            <div className='c-img'>
                <img className='c-images' src={communityImage} alt=''/>
            </div>

        </div>
    </div>
  )
}
