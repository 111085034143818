import React from 'react'
import '../../assets/styles/farmsell.css'
import Apple from '../../assets/images/Apple.svg';
import Google from '../../assets/images/Google Play.svg';
import fameImage from '../../assets/images/farmel.svg'
export default function Farmsell() {
  return (
    <div className='sel-c'>
      <div className='sel-wrapper'>
       
   <div className='sel-img'>  <img className='sel-images' src={fameImage} alt='fameImage' /></div>
        
        <div className='sel-wr'>

          <div className='sel-tex'>
         <div className='sel-heading'><h2 className='sel-h'>
              Farmsell: A Community, A Movement

            </h2></div>
           <div className='sel-para'> <p className='sel-p'>
              Farmsell - Uniting Agriculture, Catalyzing Change.Discover the Harmony of Agriculture - Download Farmsell Now!
            </p></div>
          </div>
          <div className='downLoad'>
            <div className="ios-download">
              <img src={Apple} alt="Farmsell IOS Icon" />
              <div> Download for iOS</div>
            </div>

            <div className="android-download">
              <img src={Google} alt="Farmsell IOS Icon" />
              <div style={{ color: 'white', whiteSpace: 'nowrap' }}>
                Download for Android
              </div>
            </div>
          </div>
        </div>

      </div>


    </div>
  )
}
