import '../../../assets/styles/JoinFarmsellPage.css';
import SellerBuyer from '../../../assets/images/seller-buyer.svg';
import Affiliate from '../../../assets/images/affiliate.svg';
import Funder from '../../../assets/images/Funder.svg';
import HeaderImage from '../../../assets/images/Header.svg';
import HamburgerIcon from '../../../assets/images/Menu.svg';
import Logo from '../../../assets/images/Header.svg';
import {useNavigate} from 'react-router';
import MenuBurger from '../../HarmbugMenu';
import Sidebar from '../../HarmbugMenu';
import {useEffect, useState} from 'react';
import navigateToTopPage from '../../../shared/navigateTop';
import TidoRedirect from '../../../shared/tidoRedirect';

const JoinFarmsellPage = () => {
  const navigate = useNavigate ();

  const [isMenu, openMenu] = useState (false);

  const registerWithFarmsll = name => {
    //TidoRedirect ();
    navigate ('/registration/' + name);
  };
  const redirectToHomePage = () => {
    navigate ('/');
  };

  const openSideMenu = () => {
    openMenu (!isMenu);
  };

  useEffect (() => {
    navigateToTopPage ();

    
  }, []);

  return (
    <div style={{overflowX: 'hidden'}}>
      <Sidebar isMenu={isMenu} setMenu={() => openSideMenu ()} />

      <div
        className="header-join-container"
        style={{
          backgroundColor: 'white',
          width: '100%',
          height: 100,
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
          position: 'fixed',
          top: 0,
          left: 0,
        }}
      >

        <div
          className="join-header-icon"
          onClick={() => redirectToHomePage ()}
          style={{cursor: 'pointer'}}
        >
          <img
            src={Logo}
            className="logo-header"
            alt="Join farmsell Icon"
          />
        </div>
        <button
          className="join-btn-page"
          onMouseOver={e => (e.target.style.backgroundColor = '#F7B618')}
          onMouseOut={e => (e.target.style.backgroundColor = '#3FB871')}
          onClick={() => redirectToHomePage ()}
        >
          Back Home
        </button>

        <div className="harmburg-icon-container">
          <img
            src={HamburgerIcon}
            alt="hamburger"
            className="hamburger-join-farmsell"
            onClick={() => openSideMenu ()}
          />
        </div>

      </div>

      <div className="container-join-farmsell-page">
        <div className="join-page-heading">
          <h1 className="title-join-page">

            What aspect of <br />

            {' '}
            <span className="linear-color">Farmsell </span>
            excites you the most <br />

            or aligns with your
            {' '}
            <span className="linear-color">interests?</span>
          </h1>

          <h1 className="title-join-page-mobile">

            What aspect of

            <span className="linear-color"> Farmsell </span>
            excites you the most

            or aligns with your

            <span className="linear-color">interests?</span>
          </h1>

        </div>

        <div>

          <div
            className="join-container-list"
            onClick={() => registerWithFarmsll ('Funder')}
          >

            <div className="item-container-join">
              <img src={Funder} className="img-item" />

              <h4 className="header-list">Funder</h4>

              <div className="content-join-page">
                Are you looking to provide funding
                through grants or investments?
              </div>
              <button
                className="btn-img"
                style={{marginTop: 59}}
                onClick={() => registerWithFarmsll ('Funder')}
              >
                Join Now
              </button>
            </div>
            <div
              className="item-container-join"
              onClick={() => registerWithFarmsll ('Seller')}
            >
              <img src={SellerBuyer} className="img-item" />

              <h4 className="header-list">Seller</h4>

              <div className="content-join-page">
                Are you involved in selling Agro-products, specifically as Agro-input traders, or Smallholder Farmers?
              </div>
              <button
                className="btn-img"
                onClick={() => registerWithFarmsll ('Seller')}
              >
                Join Now
              </button>
            </div>

          </div>

          <div
            className="join-container-list"
            onClick={() => registerWithFarmsll ('Affiliate')}
          >

            <div className="item-container-join">
              <img src={Affiliate} className="img-item" />

              <h4 className="header-list">Partner</h4>

              <div className="content-join-page">
                Are you interested in  partnering with Farmsell, whether you're an individual, freelancer, organization, influencer?
              </div>
              <button
                className="btn-img"
                onClick={() => registerWithFarmsll ('Affiliate')}
              >
                Join Now
              </button>
            </div>

            <div
              className="item-container-join"
              onClick={() => registerWithFarmsll ('Buyer')}
            >
              <img src={SellerBuyer} className="img-item" />

              <h4 className="header-list">Buyer</h4>

              <div className="content-join-page">
                Are you interested in purchasing products, catering to industries be it hotels, restaurants, agro-industries, supermarkets, or groceries?
              </div>
              <button
                className="btn-img"
                onClick={() => registerWithFarmsll ('Buyer')}
              >
                Join Now
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default JoinFarmsellPage;
