import React from 'react'
import '../../assets/styles/howitWorks2.css';

const StepsComponent2 = ({ imageSrc, title, sub_title, description }) => {
    return (
        <div>
            <div className="step-container2">

                <div className="text-container2">
                    <div className="circle-t">
                        <div className="circle">
                        <div className='vertical-line'></div>
                        </div>
                        <h1 className='steps-title2'>{title}</h1>
                    </div>

                    <h2 className='steps-sub-t2'>{sub_title}</h2>
                    <p className='steps-p2'>{description}</p>
                </div>
                <div className="image-container2">
                    <img className='steps-images2' src={imageSrc} alt="Step" />
                </div>

            </div>
        </div>
    )
}

export default StepsComponent2;