import '../../assets/styles/joinFarmsell.css';
import SellerBuyer from '../../assets/images/seller-buyer.svg';

import Affiliate from '../../assets/images/affiliate.svg';

import Funder from '../../assets/images/Funder.svg';

import {useNavigate} from 'react-router';
import { useEffect } from 'react';
import navigateToTopPage from '../../shared/navigateTop';

const JoinFarmsell = () => {
  const navigate = useNavigate ();


  const joinFarmsellRedirect = () => {

    
    navigate ('/join-farmsell');
  };

  useEffect(()=>{

    navigateToTopPage();
  }, [])

  return (
    <div className="join-container">
      <div className="initalContainer">
        <h3>
          {' '}Join <span>Farmsell </span> Today – Unleash the
          <br />
          Potential of Your Agricultural <span>Transactions!</span>
        </h3>
        <div className="desc-text">
          Discover the power of Farmsell – the B2B marketplace transforming the agricultural landscape. Join our growing community of farmers, hotels, supermarkets, Agro-input enterprises, and Agro-based industries. benefiting from streamlined transactions and enhanced market access.
        </div>
      </div>
      <div className="cards-top-container" >

        <div className="card-item-container" onClick={()=>joinFarmsellRedirect()}>
          <img src={Funder} className="img-item-icon" />

          <h4>Funder</h4>

          <div className="cont-desc">
            Are you looking to provide funding through grants or investments?
          </div>
          <button className="join-btn" onClick={()=>joinFarmsellRedirect()}>Join Now</button>
        </div>
        <div className="card-item-container" onClick={()=>joinFarmsellRedirect()}>
          <img src={SellerBuyer} className="img-item-icon" />

          <h4 className="header-list">Seller/Buyer</h4>

          <div className="cont-desc">
            Become a Farmsell supplier providing to the world
          </div>
          <button className="join-btn" onClick={()=>joinFarmsellRedirect()}>Join Now</button>
        </div>
        <div className="card-item-container" onClick={()=>joinFarmsellRedirect()}>
          <img src={Affiliate} className="img-item-icon" />

          <h4 className="header-list">Partner</h4>

          <div className="cont-desc">
          Are you interested in  partnering with Farmsell, whether you're an individual, freelancer, organization, influencer?
          </div>
          <button className="join-btn" onClick={()=>joinFarmsellRedirect()}>Join Now</button>
        </div>
      </div>
    </div>
  );
};

export default JoinFarmsell;
