import {Navbar, Nav, Button} from 'react-bootstrap';
import '../../assets/styles/RegPage.css';
import HeaderImage from '../../assets/images/Header.svg';
import {useNavigate, useParams} from 'react-router';
import Vec from '../../assets/images/Google.svg';
import React, {useEffect, useState} from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {useGoogleLogin} from '@react-oauth/google';
import Sidebar from '../HarmbugMenu';
import HamburgerIcon from '../../assets/images/Menu.svg';
import navigateToTopPage from '../../shared/navigateTop';
// import PhoneInput from 'react-phone-number-input/input';

const Registration = () => {
  const navigate = useNavigate ();

  const {name} = useParams ();
  // const [value, setValue] = useState ();
  const [isMenu, openMenu] = useState (false);
  const [formData, setFormData] = useState ({
    name: '',
    phone: '',
    email: '',
    password: '',
  });

  const [formError, setFormError] = useState ({
    name: '',
    phone: '',
    email: '',
    password: '',
  });

  const joinFarmsellRedirect = () => {
    navigate ('/join-farmsell');
  };
  const joinFarmsellRedirect3 = () => {
    const isNotValidated = validateForm ();

    if (isNotValidated) {
      return;
    }

    var fullName = formData.name;
    var firstName = fullName.split (' ')[0];

    navigate (`/registration/welcome/${firstName}/${name}`);
  };
  const redirectToHomePage = () => {
    navigate ('/');
  };

  const openSideMenu = () => {
    openMenu (!isMenu);
  };
  const [isChecked, setIsChecked] = useState (false);
  const handleCheckboxChange = () => {
    setIsChecked (!isChecked);
  };

  const login = useGoogleLogin ({
    onSuccess: tokenResponse => navigate ('/Registration/Welcome'),
  });

  const addFormValue = (name, e) => {
    const data = {...formData, ...{[name]: e.target.value}};

    setFormData (data);
  };

  const addFormValuePhone = e => {
    const data = {...formData, ...{phone: e}};

    setFormData (data);
  };

  const validateForm = () => {
    var status = false;

    const {name, phone, email, password} = formData;
    var dataError = {};

    if (!name) {
      status = true;
      dataError = {...dataError, ...{name: 'Name field is required'}};
    }
    if (!phone) {
      status = true;
      dataError = {...dataError, ...{phone: 'Phone field is required'}};
    }
    if (!email) {
      status = true;
      dataError = {...dataError, ...{email: 'Email field is required'}};
    }
    if (!password) {
      status = true;
      dataError = {...dataError, ...{password: 'Password field is required'}};
    }

    setFormError (dataError);

    return status;
  };

  useEffect (() => {
    navigateToTopPage ();

    const script = document.createElement ('script');
    script.src = 'https://zcsub-cmpzourl.maillist-manage.com/js/optin.min.js';
    script.onload = () => {
      // Code to execute after the script has loaded
      window.setupSF (
        'sf3z9f845699ee869b4ecf5933a776a4ee6c2f35823e4ab06720ab7b55bf9ceed23d',
        'ZCFORMVIEW',
        false,
        'acc',
        false,
        '2'
      );
    };

    document.body.appendChild (script);

    return () => {
      // Cleanup if needed
      document.body.removeChild (script);
    };
  }, []);
  return (
    <div style={{overflowX: 'hidden'}}>

      <div
        id="sf3z9f845699ee869b4ecf5933a776a4ee6c2f35823e4ab06720ab7b55bf9ceed23d"
        data-type="signupform"
      >
        <div id="customForm">
          <input type="hidden" id="recapTheme" value="2" />
          <input type="hidden" id="isRecapIntegDone" value="false" />
          <input type="hidden" id="recapMode" value="1219576000000047321" />
          <input type="hidden" id="signupFormType" value="LargeForm_Vertical" />
          <div
            name="SIGNUP_PAGE"
            id="SIGNUP_PAGE"
            class="SIGNUP_PAGE large_form_6_css"
            style={{
              backgroundColor: 'rgb(255, 255, 255)',
              color: 'rgb(100, 100, 100)',
              padding: '30px; font-family: Arial',
              textAlign: 'center',
              fontSize: 14,
            }}
          >
            <div>
              <div
                name=""
                changeid=""
                changename=""
                style={{margin: '0px auto'}}
              >
                <div
                  id="imgBlock"
                  name="LOGO_DIV"
                  logo="true"
                  style={{
                    width: 600,
                    margin: '0px auto',
                    fontFamily: 'Arial, Helvetica, sans-serif',
                    paddingBottom: 10,
                    color: 'rgb(68, 68, 68)',
                    textAlign: 'center',
                    fontSize: 12,
                    paddingTop: 10,
                    fontWeight: 'bold',
                  }}
                />
              </div>
              <br />
              <div
                id="signupMainDiv"
                style={{
                  margin: '0px auto',
                  width: '100%',
                  minWidth: 230,
                  maxWidth: 600,
                }}
                name="SIGNUPFORM"
                changeid="SIGNUPFORM"
                changename="SIGNUPFORM"
              >
                <div>
                  <div style={{position: 'relative'}}>
                    <div
                      id="Zc_SignupSuccess"
                      style={{
                        display: 'none',
                        position: 'absolute',
                        marginLeft: '4%',
                        width: '90%',
                        backgroundColor: 'white; padding: 3px; border: 3px solid rgb(194, 225, 154)',
                        marginTop: 10,
                        marginBottom: 10,
                        wordBreak: 'break-all',
                      }}
                    >
                      <table
                        width="100%"
                        cellpadding="0"
                        cellspacing="0"
                        border="0"
                      >
                        <tbody>
                          <tr>
                            <td width="10%">
                              <img
                                class="successicon"
                                src="https://zcsub-cmpzourl.maillist-manage.com/images/challangeiconenable.jpg"
                                align="absmiddle"
                              />
                            </td>
                            <td>
                              <span
                                id="signupSuccessMsg"
                                style={{
                                  color: 'rgb(73, 140, 132)',
                                  fontFamily: 'sans-serif',
                                  fontSize: 14,
                                  wordBreak: 'break-word',
                                }}
                              >
                                &nbsp;&nbsp;Thank you for Signing Up
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <form
                    method="POST"
                    id="zcampaignOptinForm"
                    style={{margin: 0}}
                    action="https://zcsub-cmpzourl.maillist-manage.com/weboptin.zc"
                    target="_zcSignup"
                  >
                    <div
                      id="SIGNUP_BODY_ALL"
                      name="SIGNUP_BODY_ALL"
                      style={{border: '1px solid rgb(255, 255, 255)'}}
                    >
                      <h1
                        style={{
                          color: 'rgb(255, 255, 255)',
                          backgroundColor: 'rgb(247, 182, 24)',
                          wordBreak: 'break-word',
                          padding: 20,
                          margin: 0,
                          borderColor: 'rgb(255, 255, 255)',
                          fontFamily: 'Arial',
                          textAlign: 'center',
                          borderWidth: 1,
                          fontSize: 20,
                          borderStyle: 'none',
                        }}
                        id="SIGNUP_HEADING"
                        name="SIGNUP_HEADING"
                        changeid="SIGNUP_MSG"
                        changetype="SIGNUP_HEADER"
                      >
                        Register Here
                      </h1>
                      <div
                        style={{
                          backgroundColor: 'rgb(64, 183, 113)',
                          color: 'rgb(237, 237, 237)',
                          padding: 20,
                          fontFamily: 'Arial',
                          textAlign: 'center',
                          bottom: 2,
                          fontSize: 12,
                          opacity: 1,
                        }}
                        id="SIGNUP_BODY"
                        name="SIGNUP_BODY"
                      >
                        <div style={{margin: '0px auto', textAlign: 'left'}}>
                          <div
                            style={{lineHeight: 1.6}}
                            class=""
                            changeid="SIGNUP_MSG"
                            id="SIGNUP_DESCRIPTION"
                            changetype="SIGNUP_DESCRIPTION"
                          >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Please fill this form to join Farmsell community and reshape agribusiness landscape?
                          </div>
                          <div
                            style={{
                              display: 'none',
                              backgroundColor: '#FFEBE8',
                              padding: '10px 10px',
                              color: '#d20000',
                              fontSize: 11,
                              margin: '10px 0px',
                              border: 'solid 1px #ffd9d3',
                              marginTop: 20,
                            }}
                            id="errorMsgDiv"
                          >
                            &nbsp;&nbsp;Please correct the marked field(s) below.
                          </div>
                          <div>
                            <div
                              style={{fontSize: 12, marginTop: 10}}
                              name="fieldsdivSf"
                              class="zcsffieldsdiv"
                            >
                              <div
                                style={{padding: '10px 0px 10px 0px'}}
                                class="zcsffield "
                                fieldid="1219576000000050299"
                              >
                                <div>
                                  <div style={{position: 'relative'}}>
                                    <div
                                      style={{
                                        color: 'rgb(51, 51, 51)',
                                        fontFamily: 'Arial, Helvetica, sans-serif',
                                        fontSize: 12,
                                      }}
                                      name="SIGNUP_FORM_LABEL"
                                    >
                                      Your Role&nbsp;

                                      <span
                                        name="SIGNUP_REQUIRED"
                                        style={{
                                          color: 'rgb(180, 0, 0)',
                                          fontFamily: 'Arial, Helvetica, sans-serif',
                                          fontSize: 11,
                                        }}
                                      >
                                        *
                                      </span>

                                    </div>
                                    <select
                                      name="CONTACT_CF3"
                                      changeitem="SIGNUP_FORM_FIELD"
                                      style={{
                                        width: '97%',
                                        color: 'rgb(100, 100, 100)',
                                        backgroundColor: 'rgb(237, 237, 237)',
                                        border: '3px solid rgb(255, 255, 255)',
                                        padding: 3,
                                        fontFamily: 'Arial',
                                        borderRadius: 0,
                                        boxSizing: 'border-box',
                                        fontSize: 14,
                                        textIndent: 5,
                                        height: 28,
                                      }}
                                      zc_display_name="Your Role"
                                    >
                                      <option value="Select Role">
                                        Select Role
                                      </option>
                                      <option value="Seller">Seller</option>
                                      <option value="Buyer">Buyer</option>
                                      <option value="Funder">Funder</option>
                                      <option value="Partner">Partner</option>
                                    </select>&nbsp;
                                    <span
                                      style={{display: 'none'}}
                                      id="dt_CONTACT_CF3"
                                    >
                                      1,true,3,Core Interest,2
                                    </span>
                                  </div>
                                </div>

                                <div style={{clear: 'both'}} />

                              </div>
                              <div
                                style={{padding: '10px 0px 10px 0px'}}
                                class="zcsffield "
                                fieldid="1219576000000000023"
                              >
                                <div>
                                  <div
                                    style={{
                                      color: 'rgb(51, 51, 51)',
                                      fontFamily: 'Arial, Helvetica, sans-serif',
                                      fontSize: 12,
                                    }}
                                    name="SIGNUP_FORM_LABEL"
                                  >
                                    First Name&nbsp;
                                    <span
                                      name="SIGNUP_REQUIRED"
                                      style={{
                                        color: 'rgb(180, 0, 0)',
                                        fontFamily: 'Arial, Helvetica, sans-serif',
                                        fontSize: 11,
                                      }}
                                    >
                                      *
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      width: '100%',
                                      minWidth: 170,
                                      marginTop: 5,
                                    }}
                                  >
                                    <input
                                      name="FIRSTNAME"
                                      changeitem="SIGNUP_FORM_FIELD"
                                      style={{
                                        width: '97%',
                                        color: 'rgb(100, 100, 100)',
                                        backgroundColor: 'rgb(237, 237, 237)',
                                        border: '3px solid rgb(255, 255, 255)',
                                        padding: 3,
                                        fontFamily: 'Arial',
                                        borderRadius: 0,
                                        boxSizing: 'border-box',
                                        fontSize: 14,
                                        textIndent: 5,
                                        height: 28,
                                      }}
                                      maxlength="100"
                                      type="text"
                                      required="true"
                                    />
                                    <span
                                      style={{display: 'none'}}
                                      id="dt_FIRSTNAME"
                                    >
                                      1,true,1,First Name,2
                                    </span>
                                  </div>
                                </div><div style={{clear: 'both'}} />
                              </div>
                              <div
                                style={{padding: '10px 0px 10px 0px'}}
                                class="zcsffield "
                                fieldid="1219576000000000025"
                              >
                                <div>
                                  <div
                                    style={{
                                      color: 'rgb(51, 51, 51)',
                                      fontFamily: 'Arial, Helvetica, sans-serif',
                                      fontSize: 12,
                                    }}
                                    name="SIGNUP_FORM_LABEL"
                                  >
                                    Last Name&nbsp;
                                    <span
                                      name="SIGNUP_REQUIRED"
                                      style={{
                                        color: 'rgb(180, 0, 0)',
                                        fontFamily: 'Arial, Helvetica, sans-serif',
                                        fontSize: 11,
                                      }}
                                    >
                                      *
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      width: '100%',
                                      minWidth: 170,
                                      marginTop: 5,
                                    }}
                                  >
                                    <input
                                      name="LASTNAME"
                                      changeitem="SIGNUP_FORM_FIELD"
                                      style={{
                                        width: '97%',
                                        color: 'rgb(100, 100, 100)',
                                        backgroundColor: 'rgb(237, 237, 237)',
                                        border: '3px solid rgb(255, 255, 255)',
                                        padding: 3,
                                        fontFamily: 'Arial',
                                        borderRadius: 0,
                                        boxSizing: 'border-box',
                                        fontSize: 14,
                                        textIndent: 5,
                                        height: 28,
                                      }}
                                      maxlength="100"
                                      type="text"
                                      required="true"
                                    />
                                    <span
                                      style={{display: 'none'}}
                                      id="dt_LASTNAME"
                                    >
                                      1,true,1,Last Name,2
                                    </span>
                                  </div>
                                </div><div style={{clear: 'both'}} />
                              </div>
                              <div
                                style={{padding: '10px 0px 10px 0px'}}
                                class="zcsffield "
                                fieldid="1219576000000000021"
                              >
                                <div>
                                  <div
                                    style={{
                                      color: 'rgb(51, 51, 51)',
                                      fontFamily: 'Arial, Helvetica, sans-serif',
                                      fontSize: 12,
                                    }}
                                    name="SIGNUP_FORM_LABEL"
                                  >
                                    Email Address&nbsp;
                                    <span
                                      name="SIGNUP_REQUIRED"
                                      style={{
                                        color: 'rgb(180, 0, 0)',
                                        fontFamily: 'Arial, Helvetica, sans-serif',
                                        fontSize: 11,
                                      }}
                                    >
                                      *
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      width: '100%',
                                      minWidth: 170,
                                      marginTop: 5,
                                    }}
                                  >
                                    <input
                                      name="CONTACT_EMAIL"
                                      changeitem="SIGNUP_FORM_FIELD"
                                      style={{
                                        width: '97%',
                                        color: 'rgb(100, 100, 100)',
                                        backgroundColor: 'rgb(237, 237, 237)',
                                        border: '3px solid rgb(255, 255, 255)',
                                        padding: 3,
                                        fontFamily: 'Arial',
                                        borderRadius: 0,
                                        boxSizing: 'border-box',
                                        fontSize: 14,
                                        textIndent: 5,
                                        height: 28,
                                      }}
                                      maxlength="100"
                                      type="email"
                                      required="true"
                                    />
                                    <span
                                      style={{display: 'none'}}
                                      id="dt_CONTACT_EMAIL"
                                    >
                                      1,true,6,Contact Email,2
                                    </span>
                                  </div>
                                </div><div style={{clear: 'both'}} />
                              </div>
                              <div
                                style={{padding: '10px 0px 10px 0px'}}
                                class="zcsffield "
                                fieldid="1219576000000050315"
                              >
                                <div>
                                  <div
                                    style={{
                                      color: 'rgb(51, 51, 51)',
                                      fontFamily: 'Arial, Helvetica, sans-serif',
                                      fontSize: 12,
                                    }}
                                    name="SIGNUP_FORM_LABEL"
                                  >
                                    Phone &nbsp;
                                    <span
                                      name="SIGNUP_REQUIRED"
                                      style={{
                                        color: 'rgb(180, 0, 0)',
                                        fontFamily: 'Arial, Helvetica, sans-serif',
                                        fontSize: 11,
                                      }}
                                    >
                                      *
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      width: '100%',
                                      minWidth: 170,
                                      marginTop: 5,
                                    }}
                                  >
                                    <input
                                      name="CONTACT_CF26"
                                      changeitem="SIGNUP_FORM_FIELD"
                                      style={{
                                        width: '97%',
                                        color: 'rgb(100, 100, 100)',
                                        backgroundColor: 'rgb(237, 237, 237)',
                                        border: '3px solid rgb(255, 255, 255)',
                                        padding: 3,
                                        fontFamily: 'Arial',
                                        borderRadius: 0,
                                        boxSizing: 'border-box',
                                        fontSize: 14,
                                        textIndent: 5,
                                        height: 28,
                                      }}
                                      maxlength="100"
                                      type="text"
                                      required="true"
                                    />
                                    <span
                                      style={{display: 'none'}}
                                      id="dt_CONTACT_CF26"
                                    >
                                      5,true,11,Phone Number,2
                                    </span>
                                  </div>
                                </div><div style={{clear: 'both'}} />
                              </div>
                              <div
                                style={{padding: '10px 0px 10px 0px'}}
                                class="zcsffield "
                                fieldid="1219576000000000039"
                              >
                                <div>
                                  <div
                                    style={{
                                      color: 'rgb(51, 51, 51)',
                                      fontFamily: 'Arial, Helvetica, sans-serif',
                                      fontSize: 12,
                                    }}
                                    name="SIGNUP_FORM_LABEL"
                                  >
                                    Country&nbsp;
                                    <span
                                      name="SIGNUP_REQUIRED"
                                      style={{
                                        color: 'rgb(180, 0, 0)',
                                        fontFamily: 'Arial, Helvetica, sans-serif',
                                        fontSize: 11,
                                      }}
                                    >
                                      *
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      width: '100%',
                                      minWidth: 170,
                                      marginTop: 5,
                                    }}
                                  >
                                    <input
                                      name="COUNTRY"
                                      changeitem="SIGNUP_FORM_FIELD"
                                      style={{
                                        width: '97%',
                                        color: 'rgb(100, 100, 100)',
                                        backgroundColor: 'rgb(237, 237, 237)',
                                        border: '3px solid rgb(255, 255, 255)',
                                        padding: 3,
                                        fontFamily: 'Arial',
                                        borderRadius: 0,
                                        boxSizing: 'border-box',
                                        fontSize: 14,
                                        textIndent: 5,
                                        height: 28,
                                      }}
                                      maxlength="100"
                                      type="text"
                                      required="true"
                                    />
                                    <span
                                      style={{display: 'none'}}
                                      id="dt_COUNTRY"
                                    >
                                      1,true,1,Country,2
                                    </span>
                                  </div>
                                </div><div style={{clear: 'both'}} />
                              </div>
                            </div>
                            <div
                              class="recaptcha"
                              style={{
                                padding: '10px 0px 10px 10px',
                                display: 'none',
                              }}
                              id="captchaOld"
                              name="captchaContainer"
                            >
                              <div>
                                <div
                                  style={{
                                    width: '59%',
                                    float: 'left',
                                    minWidth: 170,
                                    maxWidth: '70%',
                                  }}
                                  id="captchaParent"
                                >
                                  <img
                                    src="//campaigns.zoho.com/images/refresh_icon.png"
                                    style={{
                                      cursor: 'pointer',
                                      float: 'right',
                                      marginRight: 4,
                                    }}
                                    onclick="loadCaptcha('https://campaigns.zoho.com/campaigns/CaptchaVerify.zc?mode=generate',this,'#sf3z9f845699ee869b4ecf5933a776a4ee6c2f35823e4ab06720ab7b55bf9ceed23d');"
                                    id="relCaptcha"
                                  />
                                  <div
                                    id="captchaDiv"
                                    captcha="true"
                                    name=""
                                    style={{
                                      padding: 20,
                                      background: '#fff',
                                      border: '1px solid rgb(222, 222, 222)',
                                      boxSizing: 'border-box',
                                      width: '98.8%',
                                    }}
                                  />
                                  <input
                                    placeholder="Captcha"
                                    id="captchaText"
                                    name="captchaText"
                                    changeitem="SIGNUP_FORM_FIELD"
                                    style={{
                                      marginTop: 5,
                                      width: '98.7%',
                                      color: 'rgb(100, 100, 100)',
                                      backgroundColor: 'rgb(237, 237, 237)',
                                      border: '3px solid rgb(255, 255, 255)',
                                      padding: 3,
                                      fontFamily: 'Arial',
                                      borderRadius: 0,
                                      boxSizing: 'border-box',
                                      fontSize: 14,
                                      textIndent: 5,
                                      height: 28,
                                    }}
                                    maxlength="100"
                                    type="text"
                                  />
                                  <span
                                    name="SIGNUP_REQUIRED"
                                    id="capRequired"
                                    style={{
                                      color: 'rgb(180, 0, 0)',
                                      marginTop: -16,
                                      marginRight: -2,
                                      float: 'right',
                                      fontFamily: 'Arial, Helvetica, sans-serif',
                                      fontSize: 11,
                                    }}
                                  >
                                    *
                                  </span>
                                </div>
                              </div>
                              <div style={{clear: 'both'}} />
                            </div>
                            <input
                              type="hidden"
                              id="secretid"
                              value="6LdNeDUUAAAAAG5l7cJfv1AA5OKLslkrOa_xXxLs"
                            />
                            <div
                              style={{
                                borderBottom: '#ebebeb dotted 1px',
                                marginTop: 10,
                                clear: 'both',
                              }}
                            />
                            <div
                              id="REQUIRED_FIELD_TEXT"
                              changetype="REQUIRED_FIELD_TEXT"
                              name="SIGNUP_REQUIRED"
                              style={{
                                color: 'rgb(180, 0, 0)',
                                padding: '10px 10px 10px 0px',
                                fontFamily: 'Arial, Helvetica, sans-serif',
                                fontSize: 11,
                              }}
                            >
                              *Required Fields
                            </div>
                            <div
                              name="privacyPolicy"
                              style={{
                                padding: 10,
                                width: '100%',
                                position: 'relative',
                              }}
                            >
                              <input
                                type="checkbox"
                                style={{verticalAlign: 'middle'}}
                                name="PRIVACY_POLICY"
                                value="PRIVACY_AGREED"
                              />
                              <span
                                style={{verticalAlign: 'middle', marginLeft: 5}}
                              >
                                By Joining I agree to the&nbsp;
                                <a
                                  href="https://farmsell.org/terms"
                                  target="_blank"
                                  style={{textDecoration: 'none'}}
                                >
                                  Terms
                                </a>
                                &nbsp;and&nbsp;
                                <a
                                  href="https://farmsell.org/privacy"
                                  target="_blank"
                                  style={{textDecoration: 'none'}}
                                >
                                  Privacy
                                </a>
                                &nbsp;of Farmsell
                              </span>
                            </div>
                            <div style={{padding: 10, textAlign: 'center'}}>
                              <input
                                type="button"
                                action="Save"
                                id="zcWebOptin"
                                name="SIGNUP_SUBMIT_BUTTON"
                                changetype="SIGNUP_SUBMIT_BUTTON_TEXT"
                                style={{
                                  cursor: 'pointer',
                                  appearance: 'none',
                                  color: 'rgb(237, 237, 237)',
                                  backgroundColor: 'rgb(247, 182, 24)',
                                  whiteSpace: 'normal',
                                  padding: '5px 15px 5px 0px',
                                  borderColor: 'rgb(102, 0, 0)',
                                  textAlign: 'center',
                                  outline: 'none',
                                  fontFamily: 'Arial',
                                  borderRadius: 3,
                                  borderWidth: 1,
                                  fontSize: 16,
                                  backgroundPosition: 'center bottom',
                                  borderStyle: 'none',
                                  backgroundRepeat: 'repeat-x',
                                }}
                                value="Join Now"
                              />
                            </div>
                            <input type="hidden" id="fieldBorder" />
                            <input
                              type="hidden"
                              name="zc_trackCode"
                              id="zc_trackCode"
                              value="ZCFORMVIEW"
                              onload=""
                            />
                            <input
                              type="hidden"
                              name="viewFrom"
                              id="viewFrom"
                              value="URL_ACTION"
                            />
                            <input
                              type="hidden"
                              id="submitType"
                              name="submitType"
                              value="optinCustomView"
                            />
                            <input
                              type="hidden"
                              id="lD"
                              name="lD"
                              value="110eccde910983902"
                            />
                            <input
                              type="hidden"
                              name="emailReportId"
                              id="emailReportId"
                            />
                            <input
                              type="hidden"
                              name="zx"
                              id="cmpZuid"
                              value="1323bb918"
                            />
                            <input type="hidden" name="zcvers" value="3.0" />
                            <input
                              type="hidden"
                              name="oldListIds"
                              id="allCheckedListIds"
                            />
                            <input
                              type="hidden"
                              id="mode"
                              name="mode"
                              value="OptinCreateView"
                            />
                            <input
                              type="hidden"
                              id="zcld"
                              name="zcld"
                              value="110eccde910983902"
                            />
                            <input
                              type="hidden"
                              id="zctd"
                              name="zctd"
                              value="110eccde9109837a9"
                            />
                            <input type="hidden" id="document_domain" />
                            <input
                              type="hidden"
                              id="zc_Url"
                              value="zcsub-cmpzourl.maillist-manage.com"
                            />
                            <input
                              type="hidden"
                              id="new_optin_response_in"
                              value="1"
                            />
                            <input
                              type="hidden"
                              id="duplicate_optin_response_in"
                              value="1"
                            />
                            <input
                              type="hidden"
                              id="zc_formIx"
                              name="zc_formIx"
                              value="3z9f845699ee869b4ecf5933a776a4ee6c2f35823e4ab06720ab7b55bf9ceed23d"
                            />
                          </div>
                        </div>
                        <input
                          type="hidden"
                          id="isCaptchaNeeded"
                          value="false"
                        />
                        <input type="hidden" id="superAdminCap" value="0" />
                        <img
                          src="https://zcsub-cmpzourl.maillist-manage.com/images/spacer.gif"
                          onload="referenceSetter(this)"
                          id="refImage"
                          style={{display: 'none'}}
                        />
                      </div>
                      <div
                        style={{
                          margin: '0px auto',
                          marginTop: 20,
                          textAlign: 'left',
                          height: 76,
                        }}
                        id="privacyNotes"
                        identity="privacyNotes"
                      >
                        <span>
                          We protect your privacy and we guarantee that your data will be completely confidential.
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="zcOptinOverLay"
        oncontextmenu="return false"
        style={{
          display: 'none',
          textAlign: 'center',
          backgroundColor: 'rgb(0, 0, 0)',
          opacity: 0.5,
          zIndex: 100,
          position: 'fixed',
          width: '100%; top: 0px; left: 0px',
          height: 988,
        }}
      />
      <div
        id="zcOptinSuccessPopup"
        style={{
          display: 'none',
          zIndex: 9999,
          width: 800,
          height: '40%',
          top: 84,
          position: 'fixed',
          left: '26%',
          backgroundColor: '#FFFFFF',
          borderColor: '#E6E6E6',
          borderStyle: 'solid',
          borderWidth: 1,
          boxShadow: '0 1px 10px #424242',
          padding: 35,
        }}
      >
        <span
          style={{
            position: 'absolute',
            top: '-16px;right:-14px;z-index:99999',
            cursor: 'pointer',
          }}
          id="closeSuccess"
        >
          <img src="https://zcsub-cmpzourl.maillist-manage.com/images/videoclose.png" />
        </span>
        <div id="zcOptinSuccessPanel" />
      </div>

      {/* <div
        className="super-header fixed-top"
        style={{
          backgroundColor: 'white',
          width: '100%',
          top: '0',
          left: '0',
          right: '0',
          boxShadow: '0 10px 6px rgba(0, 0, 0, 0.08)', // Elevation
          zIndex: '10', // Ensure it's above other elements
        }}
      >
        <Navbar
          expand="lg"
          style={{
            backgroundColor: 'white',
            boxShadow: '0 0px 4px rgba(0, 0, 0, 0.1)',
            paddingLeft: '10%',
            paddingRight: '10%',
          }}
        >
          <Navbar.Brand onClick={() => redirectToHomePage ()}>
            <img
              src={HeaderImage}
              alt="Home Icon"
              className="home-icon img-fluid"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarNav" />
          <Navbar.Collapse id="navbarNav" className="justify-content-end">
            <Nav
              className="mr-auto"
              id="header"
              style={{marginLeft: '20%', marginRight: '0%'}}
            />
            <Button
              className="btn btn-primary"
              style={{
                color: '#ffff',
                border: 'none',
                backgroundColor: '#3FB871',
                borderRadius: '10px',

                transition: 'background-color 0.3s',
                cursor: 'pointer', // Add cursor pointer for better UX
              }}
              onMouseOver={e => (e.target.style.backgroundColor = '#F7B618')}
              onMouseOut={e => (e.target.style.backgroundColor = '#3FB871')}
              onClick={() => joinFarmsellRedirect ()}
            >
              Join Now, It's Free
            </Button>

          </Navbar.Collapse>
        </Navbar>
      </div> */}

      <Sidebar isMenu={isMenu} setMenu={() => openSideMenu ()} />

      <div
        className="header-join-container"
        style={{
          backgroundColor: 'white',
          width: '100%',
          height: 100,
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
          position: 'fixed',
          top: 0,
          left: 0,
        }}
      >

        <div
          className="join-header-icon"
          onClick={() => redirectToHomePage ()}
          style={{cursor: 'pointer'}}
        >
          <img
            src={HeaderImage}
            className="logo-header"
            alt="Join farmsell Icon"
          />
        </div>
        <button
          className="join-btn-page"
          onMouseOver={e => (e.target.style.backgroundColor = '#F7B618')}
          onMouseOut={e => (e.target.style.backgroundColor = '#3FB871')}
          onClick={() => redirectToHomePage ()}
        >
          Back Home
        </button>

        <div className="harmburg-icon-container">
          <img
            src={HamburgerIcon}
            alt="hamburger"
            className="hamburger-join-farmsell"
            onClick={() => openSideMenu ()}
          />
        </div>

      </div>

      {/* <div className="container-join-farmsell-page reg-header-mobile">
        <div className="join-page-heading-mobile">
          <h1
            style={{
              marginTop: 40,

              fontSize: 45,
            }}
          >

            Register

            {' '}
            <span className="linear-color"> here </span>
          </h1>
          <br />
          <div
            style={{
              marginTop: '-2%',

              fontSize: 25,
              color: '#274C5B',
              fontWeight: '500',
            }}
          >
            Mind sharing your contact details <br />

            with us
          </div>

          <div
            style={{
              marginTop: '6%',
              marginBottom: '6%',
            }}
          >

            <span
              style={{
                color: '#274C5B',
                fontSize: 18,
                fontWeight: '700',
              }}
            >
              {' '}You are registering as a <span
                style={{
                  color: '#3FB871',
                  fontSize: 18,
                  fontWeight: '700',
                }}
              >
                {name}
              </span>
            </span>
            <span
              style={{
                marginTop: '20%',
                color: '#F71818',
                fontSize: 18,
                fontWeight: '700',
                marginLeft: 8,
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => joinFarmsellRedirect ()}
            >
              Change
            </span>

          </div>

        </div>   {' '}

        <div className="join-page-heading1">
          <h1
            style={{
              marginTop: 40,

              fontSize: 45,
            }}
          >

            Register

            {' '}
            <span className="linear-color"> here </span> <br />
            <br />

          </h1>

          <div
            style={{
              marginTop: '-2%',

              fontSize: 25,
              color: '#274C5B',
              fontWeight: '500',
            }}
          >
            Mind sharing your contact details <br />

            with us

          </div>

          <div
            style={{
              marginTop: '6%',
              marginBottom: '6%',
            }}
          >

            <span
              style={{
                color: '#274C5B',
                fontSize: 18,
                fontWeight: '700',
              }}
            >
              {' '}You are registering as a <span
                style={{
                  color: '#3FB871',
                  fontSize: 18,
                  fontWeight: '700',
                }}
              >
                {name}
              </span>
            </span>
            <span
              style={{
                marginTop: '20%',
                color: '#F71818',
                fontSize: 18,
                fontWeight: '700',
                marginLeft: 8,
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => joinFarmsellRedirect ()}
            >
              Change
            </span>

          </div>

        </div>   {' '}
      </div> */}

      {/* <Button
        type="button"
        className="btn-google footer-style-reg"
        style={{marginTop: '-6%'}}
        onClick={() => login ()}
      >
        <img src={Vec} style={{marginLeft: '0%'}} />
        <span
          style={{
            marginLeft: '2%',
            color: '#565656',

            fontFamily: 'Poppins,sans-serif',
            fontSize: '18px',

            fontWeight: '400',
            lineHeight: 'normal',
            marginLeft: '2%',
          }}
        >
          {' '}Join with Google
        </span>
      </Button> */}

      {/* <div style={{textAlign: 'center'}}>
        <span style={{color: '#000000', fontSize: '14px', fontWeight: 'bold'}}>
          <hr className="reg-line" />
          OR
          <hr className="reg-line" />
        </span>
      </div> */}
      {/* <div>
        <div className="input-form" style={{marginTop: 0}}>
          <input
            type="text"
            placeholder="Write your first name here"
            value={formData.name}
            className="input-custom-style"
            onChange={e => addFormValue ('name', e)}
          />
          {formError.name &&
            <span className="custom-validation">{formError.name}</span>}
          <input
            value={formData.email}
            type="email"
            className="input-custom-style"
            onChange={e => addFormValue ('email', e)}
            placeholder="Write your second name here"
          />
          {formError.email &&
            <span className="custom-validation">{formError.email}</span>}
          <input
            type="password"
            value={formData.password}
            className="input-custom-style"
            onChange={e => addFormValue ('password', e)}
            placeholder="Write your email address here"
          />
          {formError.password &&
            <span className="custom-validation">{formError.password}</span>}
       

          <PhoneInput
            country={'ug'}
            value={formData.phone}
            onChange={e => addFormValuePhone (e)}
            className="phone-input-field"
            containerStyle={{
              width: 422,
              marginBottom: 10,
              backgroundColor: '#f5f5f5',
            }}
            inputStyle={{width: 422, height: 50, backgroundColor: '#f5f5f5'}}
          />

          <div className="terms-reg" style={{marginBottom: 40}}>

            <input
              type="checkbox"
              id="myCheckbox"
              name="myCheckbox"
              checked={isChecked}
              style={{display: 'none'}}
              onChange={handleCheckboxChange}
            />
            <label
              htmlFor="myCheckbox"
              style={{
                position: 'relative',
                cursor: 'pointer',
                padding: '5px',
                borderRadius: '3px',
                marginRight: '5px',
                width: '16px',
                height: '16px',
                backgroundColor: '#FFFFFF',
                border: '1px solid #3FB871',
              }}
            >
              <span
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  display: isChecked ? 'block' : 'none',
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#000000"
                  className="bi bi-check"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 7l5 5L13 0l2 2-10 10L3 9z" />
                </svg>
              </span>
            </label>

            <span className="terms-font">
              By proceeding, you agree to Farmsell
              <a
                href="/terms"
                style={{color: 'green', textDecoration: 'underline'}}
              >
                {'  '} Terms
              </a>
              {'  '} and
              <a
                href="/privacy"
                style={{color: 'green', textDecoration: 'underline'}}
              >
                {' '} Privacy
              </a>.
            </span>

          </div>
          {formError.phone &&
            <span className="custom-validation">{formError.phone}</span>}

          <button type="submit" onClick={() => joinFarmsellRedirect3 ()}>
            Join
          </button>
        </div>

      </div> */}
      <div className="terms-reg-container">

        <div>
          {/* <div
    className="super-header fixed-top"
    style={{
      backgroundColor: 'white',
      width: '100%',
      top: '0',
      left: '0',
      right: '0',
      boxShadow: '0 10px 6px rgba(0, 0, 0, 0.08)', // Elevation
      zIndex: '10', // Ensure it's above other elements
    
    }}
  >
    <Navbar
      expand="lg"
      style={{
        backgroundColor: 'white',
        boxShadow: '0 0px 4px rgba(0, 0, 0, 0.1)',
        paddingLeft: '10%',
        paddingRight: '10%',
       
      }}
    >
      <Navbar.Brand href="#home">
        <img
          src={HeaderImage}
          alt="Home Icon"
          className="home-icon img-fluid"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarNav" />
      <Navbar.Collapse id="navbarNav" className="justify-content-end">
        <Nav
          className="mr-auto"
          id="header"
          style={{marginLeft: '20%',  marginRight: '0%'}}
        >
         
        </Nav>
        <Button
          className="btn btn-primary"
          style={{
            color: '#ffff',
            border: 'none',
            backgroundColor: '#3FB871',
            borderRadius: '10px',
        
        
            transition: 'background-color 0.3s',
            cursor: 'pointer', // Add cursor pointer for better UX
          }}
          onMouseOver={e => (e.target.style.backgroundColor = '#F7B618')}
          onMouseOut={e => (e.target.style.backgroundColor = '#3FB871')}
          onClick={() => joinFarmsellRedirect ()}
        >
          Join Now, It's Free
        </Button>

      </Navbar.Collapse>
    </Navbar>
  </div> */}

        </div>{' '}
      </div>{' '}
    </div>
  );
};

export default Registration;
