import React, { useState } from 'react';
import '../../../assets/styles/about-us/ourapproach.css';
import RightArrow from '../../../assets/images/aboutus/greenrightarrow.svg';
import LeftArrow from '../../../assets/images/aboutus/whiteleftarrow.svg';

const OurApproach = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const contentItems = [
        {
            title: 'Empowering Smallholder Farmers and Harvesting Success',
            description: "Empowering Smallholder Farmers and Harvesting Success: At Farmsell, we recognize the vital role smallholder farmers play in our food supply chain. Our approach begins with empowering these farmers, breaking the shackles of exploitation and inconvenience. Through our platform, farmers gain direct access to reliable markets, reducing post-harvest losses and significantly boosting their income. We stand by them, offering a lifeline that transcends geographical boundaries.",
        },
        {
            title: 'Dedicated to Agriculture for all Stakeholders',
            description: "Farmsell is not just a marketplace; it's a dedicated hub for agriculture. We cater to the diverse needs of stakeholders across the sector – from smallholder farmers seeking reliable markets and quality inputs to SMEs requiring direct access to customers, and institutions and industries aiming to streamline their supply chain. Our holistic approach ensures that every participant in the agricultural ecosystem finds tailored solutions under one digital roof",
        },
        {
            title: 'Empowering Smallholder Farmers and Harvesting Success',
            description: "Empowering Smallholder Farmers and Harvesting Success: At Farmsell, we recognize the vital role smallholder farmers play in our food supply chain. Our approach begins with empowering these farmers, breaking the shackles of exploitation and inconvenience. Through our platform, farmers gain direct access to reliable markets, reducing post-harvest losses and significantly boosting their income. We stand by them, offering a lifeline that transcends geographical boundaries.",
        },
        {
            title: 'Dedicated to Agriculture for all Stakeholders',
            description: "Farmsell is not just a marketplace; it's a dedicated hub for agriculture. We cater to the diverse needs of stakeholders across the sector – from smallholder farmers seeking reliable markets and quality inputs to SMEs requiring direct access to customers, and institutions and industries aiming to streamline their supply chain. Our holistic approach ensures that every participant in the agricultural ecosystem finds tailored solutions under one digital roof",
        },
        {
            title: 'Empowering Smallholder Farmers and Harvesting Success',
            description: "Empowering Smallholder Farmers and Harvesting Success: At Farmsell, we recognize the vital role smallholder farmers play in our food supply chain. Our approach begins with empowering these farmers, breaking the shackles of exploitation and inconvenience. Through our platform, farmers gain direct access to reliable markets, reducing post-harvest losses and significantly boosting their income. We stand by them, offering a lifeline that transcends geographical boundaries.",
        },
        {
            title: 'Dedicated to Agriculture for all Stakeholders',
            description: "Farmsell is not just a marketplace; it's a dedicated hub for agriculture. We cater to the diverse needs of stakeholders across the sector – from smallholder farmers seeking reliable markets and quality inputs to SMEs requiring direct access to customers, and institutions and industries aiming to streamline their supply chain. Our holistic approach ensures that every participant in the agricultural ecosystem finds tailored solutions under one digital roof",
        },
    ];

    const visibleItems = window.innerWidth <= 768 ? contentItems.length - 5 : contentItems.length - 5;
    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % contentItems.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? contentItems.length - 1 : prevIndex - 1
        );
    };

    return (
        <div className='ourapproach'>
            <div className='header'> 
                <h2>Our <span className='colored'>Approach</span>.<br />Cultivating Prosperity Through <span className='colored'>Innovation</span></h2>

                <div className='desc-text'>
                Our commitment goes beyond being just a platform; we are the catalyst for positive change, transforming the agricultural landscape across Africa and beyond. Discover the essence of our approach as we embark on a transformative journey with key stakeholders.
                </div>
            </div>

            <div className="our-approach-container">
                <div className="box-container">
                    <div className="content-box">
                        <h2>{contentItems[currentIndex].title}</h2>
                        <p>{contentItems[currentIndex].description}</p>
                    </div>
                    <div className="content-box2">
                        <h2>{contentItems[(currentIndex + 1) % contentItems.length].title}</h2>
                        <p> {contentItems[(currentIndex + 1) % contentItems.length].description}</p>
                    </div>
                </div>

                <div className='lower-sect'>
                    <div className="navigation-buttons">
                        <button
                            onClick={handlePrev}
                            className={`prev-button ${currentIndex === 0 ? 'disabled' : ''}`}
                        >
                            <img src={LeftArrow} alt='leftarrow' />
                        </button>
                        <button
                            onClick={handleNext}
                            className={`next-button ${currentIndex >= contentItems.length - visibleItems ? 'disabled' : ''}`}
                        >
                            Next <span><img src={RightArrow} alt='rightarrow'/></span>
                        </button>
                    </div>

                    <div className="dot-container">
                        {contentItems.map((_, index) => (
                            <div
                                key={index}
                                className={`dot ${index === currentIndex ? 'active' : ''}`}
                            ></div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurApproach;
