import React from 'react'
import '../../assets/styles/Webinars.css'
import ButtonArrow from '../../assets/images/welcomebuttonarrow.svg';
import webinarImage from '../../assets/images/webinars.svg'
export default function Webinars() {
  return (
    <div className='w-c'>


    <div className='w-wrapper'>

        <div className='w-header'>
            
            <h2 className='heading'>
                <span className='bg-text'>Webinars</span><br/>
               <span style={{color:"#274C5B"}}>   Knowledge Unleashed, Unity <br/>Strengthened</span>
                </h2>
              
              
              
             
        </div>
        <div className='w-w'>
            
            <div className='w-text'>
               <div className='w-para'> <p className='w-p'>
                Embark on a journey of continuous learning with Farmsell's live webinars. Our online sessions are more than just educational; they are opportunities to empower yourself with the latest industry trends, technological advancements, and market insights. Whether you're a smallholder farmer or an agro-industry expert, our webinars are tailored to elevate your understanding and connect you with a community that shares your passion. Enrich your knowledge – join our upcoming webinar today.



                </p></div>
                <button
                className='w-btn'

    >
     Explore Upcoming Webinars
      <span className=''>
        <img src={ButtonArrow} alt='buttonarrow' />
      </span>
    </button>
            </div>
            <div className='w-img'>
                <img className='f-images' src={webinarImage} alt='forumImage'/>
            </div>

        </div>
    </div>
</div>
  )
}
