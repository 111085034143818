import React from 'react';
import {slide as Menu} from 'react-burger-menu';
import { useNavigate } from 'react-router';


var styles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '36px',
    height: '30px',

    right: '153px',
    display: 'none',
  },
  bmBurgerBars: {
    background: '#373a47',
  },
  bmBurgerBarsHover: {
    background: '#a90000',
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
  },
  bmCross: {
    background: '#bdc3c7',
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    width: '100%',
  },
  bmMenu: {
    background: 'white',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em',
  },
  bmMorphShape: {
    fill: '#373a47',
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em',
  },
  bmItem: {
    display: 'inline-block',
    width: '100%',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
};

const Sidebar = ({isMenu, setMenu}) => {
  const isMenuOpen = () => {
    setMenu (false);
  };

  const navigate=useNavigate();

  const joinFarmsellRedirect = () => {
    navigate ('/join-farmsell');
  };

  return (
    <Menu isOpen={isMenu} styles={styles} onClose={() => isMenuOpen ()}>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >

        <div>
          <div style={{backgroundColor: 'rgba(249, 249, 255, 1)'}}>
            <a id="home" className="menu-item" href="/">Home</a>
          </div>
          <div style={{marginTop:5}}>
            <a id="about" className="menu-item" href="/">Services</a>
          </div>
          <div style={{marginTop:5}}>
            <a id="contact" className="menu-item" href="/HowItWorks">
            How We Work
            </a>
          </div>

          <div style={{marginTop:5}}>

            <a id="contact" className="menu-item" href="/AboutUs">
            About Us
            </a>
          </div>
        </div>

        <button
          className="join-btn-page-harmbug"
          onMouseOver={e => (e.target.style.backgroundColor = '#F7B618')}
          onMouseOut={e => (e.target.style.backgroundColor = '#3FB871')}
          style={{
            marginTop: '84%',
            alignSelf: 'center',
          }}
           onClick={() => joinFarmsellRedirect ()}
        >
          Go to farmsell.org
        </button>

      </div>
    </Menu>
  );
};

export default Sidebar;
