import {Navbar, Nav, Button} from 'react-bootstrap';
import '../../assets/styles/RegPage.css';

import HeaderImage from '../../assets/images/Header.svg';

// import Seller from '../../assets/images/1.mp4';
// import Funder from '../../assets/images/2.mp4';
// import Buyer from '../../assets/images/3.mp4';
// import Affiliate from '../../assets/images/3.mp4';
import Shadow from '../../assets/images/Shadow.png';
import Shadow2 from '../../assets/images/Shadow (1).png';

import imgFooter from '../../assets/images/Group 1272628218.svg';
import {useNavigate, useParams} from 'react-router';
import Sidebar from '../HarmbugMenu';
import HamburgerIcon from '../../assets/images/Menu.svg';
import {useEffect, useState} from 'react';
import navigateToTopPage from '../../shared/navigateTop';

// import Vec from '../../assets/images/Google.svg';

// import Phone from '../../assets/images/Group 1272628218.svg';
const WelcomePage = () => {
  const navigate = useNavigate ();

  const {name} = useParams ();
  const {type} = useParams ();
  // const joinFarmsellRedirect = () => {
  //   navigate ('/join-farmsell');
  // };

  // const joinFarmsellRedirect3 = () => {
  //   navigate ('/Registration/Welcome');
  // };

  const [isMenu, openMenu] = useState (false);
  const redirectToHomePage = () => {
    navigate ('/');
  };

  const openSideMenu = () => {
    openMenu (!isMenu);
  };

  // const setType = type => {
  //   if (type === 'Seller') {
  //     return Seller;
  //   }

  //   if (type === 'Buyer') {
  //     return Buyer;
  //   }

  //   if (type === 'Affiliate') {
  //     return Affiliate;
  //   }

  //   if (type === 'Funder') {
  //     return Funder;
  //   }
  // };

  const Video = 'https://farmsell.org/API/Welcome-General.mp4';

  useEffect (() => {
    navigateToTopPage ();

    const video = document.getElementById ('videoTag');

    if (video) {
      video.muted = true;

      video.play ();
    }
  }, []);

  return (
    <div>

      <Sidebar isMenu={isMenu} setMenu={() => openSideMenu ()} />

      <div
        className="header-join-container"
        style={{
          backgroundColor: 'white',
          width: '100%',
          height: 100,
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
          position: 'fixed',
          top: 0,
          left: 0,
        }}
      >

        <div
          className="join-header-icon"
          onClick={() => redirectToHomePage ()}
          style={{cursor: 'pointer'}}
        >
          <img
            src={HeaderImage}
            className="logo-header"
            alt="Join farmsell Icon"
          />
        </div>
        <button
          className="join-btn-page"
          onMouseOver={e => (e.target.style.backgroundColor = '#F7B618')}
          onMouseOut={e => (e.target.style.backgroundColor = '#3FB871')}
          onClick={() => redirectToHomePage ()}
        >
          Back Home
        </button>

        <div className="harmburg-icon-container">
          <img
            src={HamburgerIcon}
            alt="hamburger"
            className="hamburger-join-farmsell"
            onClick={() => openSideMenu ()}
          />
        </div>

      </div>
      {/* <div
    className="super-header fixed-top"
    style={{
      backgroundColor: 'white',
      width: '100%',
      top: '0',
      left: '0',
      right: '0',
      boxShadow: '0 10px 6px rgba(0, 0, 0, 0.08)', // Elevation
      zIndex: '10', // Ensure it's above other elements
    
    }}
  >
    <Navbar
      expand="lg"
      style={{
        backgroundColor: 'white',
        boxShadow: '0 0px 4px rgba(0, 0, 0, 0.1)',
        paddingLeft: '10%',
        paddingRight: '10%',
       
      }}
    >
      <Navbar.Brand href="#home">
        <img
          src={HeaderImage}
          alt="Home Icon"
          className="home-icon img-fluid"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarNav" />
      <Navbar.Collapse id="navbarNav" className="justify-content-end">
        <Nav
          className="mr-auto"
          id="header"
          style={{marginLeft: '-134px', gap: '37px', marginRight: '159px'}}
        >
         
        </Nav>
        <Button
          className="btn btn-primary"
          style={{
            color: '#ffff',
            border: 'none',
            backgroundColor: '#3FB871',
            borderRadius: '10px',
            padding: '20px',
            marginRight: '70px',
            transition: 'background-color 0.3s',
            cursor: 'pointer', // Add cursor pointer for better UX
          }}
          onMouseOver={e => (e.target.style.backgroundColor = '#F7B618')}
          onMouseOut={e => (e.target.style.backgroundColor = '#3FB871')}
          onClick={() => joinFarmsellRedirect ()}
        >
          Go Back
        </Button>

      </Navbar.Collapse>
    </Navbar>
  </div> */}
      <div className="welcome-page-web">
        <div style={{marginBottom: 20}}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >

            {/* <img src={Video} /> */}

            <video
              style={{
                marginTop: '15%',
                width: '30%',
                height: '30%',
                marginLeft: '15%',
              }}
              autoPlay
              controls
              id="videoTag"
            >
              <source src={Video} type="video/mp4" />
              <source src={Video} type="video/ogg" />
              Your browser does not support the video tag.
            </video>
            <span
              className="linear-color"
              style={{
                marginTop: '10%',
                marginLeft: '10%',
                fontWeight: '600',
                fontSize: 45,
              }}
            >
              Welcome to Farmsell!
            </span>

          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <img
              src={Shadow2}
              style={{
                marginTop: '-15%',
                width: '40%',
                height: '40%',
                marginLeft: '5%',
              }}
            />
            <img
              src={Shadow}
              style={{
                marginTop: '-15%',
                width: '40%',
                height: '40%',
                marginLeft: '-25%',
              }}
            />
          </div>

          <div style={{marginLeft: '53%', marginTop: '-24%'}}>

            <p className="hey3">
              We're excited to have you with us. Could you take a moment to
              <br />
              {' '}
              watch a special message from our Founder? Your attention
              {' '}
              <br />
              {' '}
              means a lot to us. Thanks a bunch!
            </p>
          </div>
        </div>
        <div>

          <div className="hey" style={{marginTop: '10%'}}>

            Hey {' '}
            <span className="linear-color"> {name}!</span> {' '}
            Ready for an <br />
            <div>amazing Farmsell experience? </div>

            <span
              className="linear-color"
              style={{display: 'block', marginLeft: '0%'}}
            >
              Let's Go!
            </span>
          </div>

        </div> <div style={{marginLeft: '10%', marginTop: '0.5%'}}>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <ul style={{fontSize: 25, listStyleType: 'disc'}}>
              <li>
                {' '}
                <span style={{fontWeight: 'bold'}}>Check Email Inbox:</span>
                {' '}
                Go to your registered email address
              </li>
              <li>
                {' '}
                <span style={{fontWeight: 'bold'}}>Open Our Email:</span>
                {' '}
                With Subject "Welcome to Farmsell: Together we redefined Agro trading."
              </li>
              <li>
                {' '}
                <span style={{fontWeight: 'bold'}}>Check Junk/Spam: </span>
                Just in case, check junk/spam folders of your email address
              </li>

              <li>
                <span style={{fontWeight: 'bold'}}>
                  Read and Act:
                </span>
                {' '}
                Follow the instructions in the email from Farmsell.

              </li>
              <li>
                {' '}
                <span style={{fontWeight: 'bold'}}>Confirm Receipt: </span>
                Shoot us a quick reply to confirm you're all set!
              </li>
            </ul>

            <div style={{marginLeft: '2%', marginTop: -245}}>

              <img src={imgFooter} style={{width: '80%', height: '80%'}} />
              <img src={Shadow} style={{width: '80%', height: '80%'}} />
            </div>

          </div>

        </div>{' '}
      </div>

      <div className="welcome-page-mobile">

        <div
          style={{
            marginTop: 110,
            marginBottom: 40,

            marginLeft: '10%',
          }}
        >

          <span
            className="linear-color"
            style={{
              fontWeight: '600',
              fontSize: 25,
              marginTop: '10%',
            }}
          >
            Welcome to Farmsell!
          </span>

          <div>

            We're excited to have you with us. Could you take a moment to watch a special
            {' '}
            message from our Founder? Your attention means a lot to us. Thanks a bunch!
          </div>
        </div>
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >

            <video
              style={{
                width: '80%',
                height: '28%',
                marginLeft: '10px',
              }}
              autoPlay
              controls
              id="videoTag"
            >
              <source src={Video} type="video/mp4" />
              <source src={Video} type="video/ogg" />
              Your browser does not support the video tag.
            </video>

          </div>
        </div>
        <div>

          <div className="hey" style={{marginTop: '10%', fontSize: 25}}>

            Hey {' '}
            <span className="linear-color"> Daniel!</span> {' '}
            Ready for an <br />
            <div>amazing Farmsell experience? </div>

            <span
              className="linear-color"
              style={{display: 'block', marginLeft: '0%'}}
            >
              Let's Go!
            </span>
          </div>

        </div> <div style={{marginLeft: '9%', marginTop: '0.5%'}}>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <ul style={{fontSize: 16, listStyleType: 'disc'}}>
              <li>
                {' '}
                <span style={{fontWeight: 'bold'}}>Check Email Inbox:</span>
                {' '}
                Go to your registered email address
              </li>
              <li>
                {' '}
                <span style={{fontWeight: 'bold'}}>Open Our Email:</span>
                {' '}
                With Subject "Welcome to Farmsell: Together we redefined Agro trading."
              </li>
              <li>
                {' '}
                <span style={{fontWeight: 'bold'}}>Check Junk/Spam: </span>
                Just in case, check junk/spam folders of your email address
              </li>

              <li>
                <span style={{fontWeight: 'bold'}}>
                  Read and Act:
                </span>
                {' '}
                Follow the instructions in the email from Farmsell.

              </li>
              <li>
                {' '}
                <span style={{fontWeight: 'bold'}}>Confirm Receipt: </span>
                Shoot us a quick reply to confirm you're all set!
              </li>
            </ul>

            {/* <div style={{marginLeft: '2%'}}>

              <img src={Shadow2} style={{width: '80%', height: '80%'}} />
              <img src={Shadow} style={{width: '80%', height: '80%'}} />
            </div> */}

          </div>

          <img
            src={imgFooter}
            style={{width: '80%', marginTop: 20, height: '80%'}}
          />

        </div>{' '}
      </div>
    </div>
  );
};

export default WelcomePage;
