// VisionAndMission.js

import React from 'react';
import '../../assets/styles/VisionAndMission.css';
import visionImage from '../../assets/images/vision-image.png'; // Replace with the actual path
import missionImage from '../../assets/images/mission-image.png'; // Replace with the actual path

const VisionAndMission = () => {
    return (
        <div className="vision-mission-container">
            <div className="vision-section">
                <h2 className="vision-heading">
                    <div className="vision-mission-contai">
                        <span className="vision">Vision</span> and <span className="mission">Mission .</span>
                    </div>
                    Nurturing Growth in Agriculture
                </h2>

                <p className="vision-paragraph">
                    Explore our commitment to positive agricultural transformation through our vision and mission. Cultivating a Future of Prosperity, Sustainability, and Technological Advancement in Agriculture.
                </p>
            </div>

            <div className="mission-section">
                <div className="text-contaiinerxxddzz">
                    <h2 className='minfs'>Vision Statement</h2>
                    <p className="text-containep">
                        At Farmsell, we envision <span className="flourishing-text"> a flourishing agricultural landscape characterized by enhanced efficiency, sustainability, and profitability.</span> We see Farmsell as the catalyst for positive change within the B2B agricultural marketplace, fostering growth and prosperity for all stakeholders.
                    </p>
                </div>
                <div className="image-container">
                    <img src={visionImage} alt="Vision" />
                </div>
            </div>

            <div className="missiondd-section" style={{ marginTop: '11px' }}>
                <div className="container-flex">
                    <div className="text-containersd">
                        <h2 className='minf'>Mission Statement</h2>
                        <p className="textntainer">
                            At Farmsell, we strive to <span className="flourishing-text"> revolutionize transactions within the agricultural ecosystem for prosperity of all</span>We achieve this by providing a dynamic platform that empowers farmers, enterprises, and institutions for profitability. We simplify and streamline processes, fostering a community committed to sustainable practices and technological advancements.
                        </p>
                    </div>
                    <div className="image-coccntainer img">
                        <img src={missionImage} alt="Mission" className='missionImage' />
                    </div>
                </div>
            </div>

        </div>

        
    );
};

export default VisionAndMission;
