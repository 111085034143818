import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/styles/WelcomeToFarmsell.css';
import OIDImage5 from '../../assets/images/ourservice/Component 30.png';
import ButtonArrow from '../../assets/images/welcomebuttonarrow.svg';
import {useNavigate} from 'react-router';

const WelcomeToFarmsell = () => {

  const navigate = useNavigate ();


  const aboutFarmsellRedirect = () => {

    
    navigate ('/AboutUs');
  };
  return (
    <div className="super-trustedClient">
      <div className="textt-cont">
        <h1>
          Welcome to
          <span className="gradient-texxt"> Farmsell</span>
        </h1>
        <p>
          Where agriculture meets innovation, and transactions seamlessly
          transcend to a whole new level. Farmsell introduces a groundbreaking
          B2B Marketplace meticulously crafted to meet the unique needs of the
          agricultural sector in Africa and beyond. More than just a digital
          platform, Farmsell is a transformative force addressing the
          longstanding challenges of the agricultural sector. It stands as a
          beacon of efficiency, reliability, and empowerment.
        </p>
        <button className="learn-more-btn" onClick={(navigate)=> aboutFarmsellRedirect()}>
          Learn More
          <span className='ms-4'>
            <img src={ButtonArrow} alt='buttonarrow' />
          </span>
        </button>
      </div>
      <div className='cont-image'>
        <img src={OIDImage5} alt="Image 5" />
      </div>
    </div>
  );
};

export default WelcomeToFarmsell;
