// WhyFarmSell.js
import React, { useState, useEffect } from 'react';
import '../../assets/styles/whyfarmsell.css';
import Image1 from '../../assets/images/whyfarmsell1.jpg';
import Image2 from '../../assets/images/whyfarmsell2.jpg';
import Image3 from '../../assets/images/whyfarmsell3.jpg';

const cardData = [
  {
    title: 'Direct Market Access',
    description: 'Bid farewell to middlemen and cumbersome market travels. Farmsell opens the gateway to direct market access for everyone – from smallholder farmers to farmers, hotels/supermarkets, and Agro-based industries. This liberates sellers from exploitation, eliminates inconvenience, and ensures that all stakeholders have a direct route to reliable markets. By seamlessly connecting a diverse range of sellers with buyers, we not only reduce post-harvest losses but also significantly boost income across the agricultural spectrum. No more exploitation by middlemen or inconvenient physical market travels – Farmsell brings the market to your fingertips.',
    backgroundImage: Image1,
    backgroundColor: '#3FB871',
  },
  {
    title: 'Exclusively Dedicated to Agriculture',
    description: 'Farmsell stands out as a specialized platform exclusively committed to agriculture, distinguishing itself from generic B2B marketplaces. Within the agricultural industry, we understand the finer details, delivering a specialized platform crafted to cater to the specific needs of farmers, hotels, Agro-input enterprises, and Agro-based industries. Our commitment to agriculture goes beyond the generic B2B approach. Farmsell provides a comprehensive solution that streamlines both bulk selling and buying processes, prioritizing, and addressing the specific requirements of the agricultural sector.',
    backgroundImage: Image2,
    backgroundColor: '#F7B618',
  },
  {
    title: 'Smart Matching for Efficiency',
    description: "Farmsell's Smart Matching redefines B2B transactions in agriculture. Harnessing cutting-edge artificial intelligence, it ensures perfect connections between sellers and buyers. This transformative feature optimizes transactions, expediting the exchange of quality agricultural products. With Farmsell's Smart Matching, every interaction becomes a precise, efficient, and tailored experience, setting a new standard in the agricultural marketplace.",
    backgroundImage: Image3,
    backgroundColor: '#3FB871',
  },
];

const WhyFarmSell = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div className="why-farm-sell">
      <div className='heading'>
        <h2>Why Farmsell? <span>Transforming</span> <br />
          Agriculture, One <span>Transaction</span> at a Time </h2>
        <div className='desc-text'>In a world where the agricultural market is often fragmented and unpredictable, Farmsell emerges as a game-changer. Our uniqueness lies in addressing the longstanding challenges faced by farmers, hotels, Agro-input enterprises, and Agro-based industries.</div>
      </div>


      <div className='content'>
        {cardData.map((card, index) => (
          <div
            key={index}
            className={`card ${index === hoveredIndex ? 'hovered' : ''}`}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
            style={{ 
              backgroundImage: isMobile ? `` : `url(${card.backgroundImage})`,
              backgroundColor: isMobile ? card.backgroundColor : 'transparent'
             }}
           
          >

            <div className='temp-h2'>
              <h2>{card.title}</h2>
            </div>

            <div className={`card-content ${index === hoveredIndex ? 'show' : ''}`}>
              <div className='conc-h2'><h2>{card.title}</h2></div>
              <div className='conc-p' ><p>{card.description}</p></div>
            </div>
          </div>
        ))}
      </div>
    </div>

  );
};

export default WhyFarmSell;
