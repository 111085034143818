import React, { useState } from 'react';
import '../../../assets/styles/about-us/StrategicComponent.css';
import Cherron from '../../../assets/images/aboutus/Chevron Left.png';
import image3 from '../../../assets/images/aboutus/image 3.png';
import image6 from '../../../assets/images/aboutus/image 6.png';
import image8 from '../../../assets/images/aboutus/image 8.png';
import image9 from '../../../assets/images/aboutus/image 9.png';
import image10 from '../../../assets/images/aboutus/image 10.png';
import image7 from '../../../assets/images/aboutus/image 7.png';

const StrategicComponent = () => {
    const [activeHeader, setActiveHeader] = useState(1);

    const headersData = [
        {
            id: 1,
            title: 'Market Access Amplified',
            content: 'Farmscape isn\'t just a platform; it\'s a gateway to expanded market reach. Our smart-sourcing capabilities, fueled by artificial intelligence, ensure that your products or services find the right audience efficiently. By partnering with Farmscape, you tap into a vast network that spans smallholder farmers, agri-input enterprises, institutions, and industries, amplifying your impact and market presence',
        },
        {
            id: 2,
            title: 'Tech-Driven Efficiency',
            content: 'Farmsell leverages cutting-edge technology to streamline the agricultural supply chain. As a partner, you benefit from our innovative solutions that enhance operational efficiency, reduce costs, and bring transparency to the entire value chain. From smart matchmaking to data-driven insights, Farmsell empowers you to make informed decisions and stay ahead in the competitive agricultural marketplace.',
        },
        {
            id: 3,
            title: 'Impactful Social Change',
            content: "Beyond profits, Farmsell is committed to fostering positive change in the agricultural sector. By partnering with us, you contribute to the reduction of post-harvest losses, the empowerment of smallholder farmers, and the overall improvement of food security and income. Farmsell isn't just a marketplace; it's a catalyst for meaningful social impact.",

        },
    ];

    const toggleHeader = (headerId) => {
        setActiveHeader(headerId === activeHeader ? null : headerId);
    };

    return (
        <div>
            <div className="new-strategic-component">
                <div className="new-con">
                    <div className="new-imaContain">
                        <h1>Strategic<span className='Partn'>Partnerships</span> : Cultivating Growth, Impact, and Sustainability</h1>

                    </div>
                    <div className="new-paraContainer">
                        <p>In the dynamic landscape of the agricultural sector, collaboration is the key to overcoming challenges and reaping the benefits of innovation. Farmscape invites you to join us on this journey of growth, efficiency, and sustainability through strategic alliances that go beyond transactional exchanges.</p>
                    </div>
                </div>
                <div className="new-content-container">
                    <div className='labora'>
                        <h1 className='Collab'>Why Partner with Farmsell?</h1>
                        <p className='Farmscape'>At Farmscape, we understand that true success in agriculture requires a collective effort. That's why we are actively seeking partners who share our vision of a thriving and sustainable agricultural ecosystem. Whether you are a contributor, investor, international organization, or governmental entity, Farmscape offers a unique set of advantages for those who choose to collaborate with us</p>
                    </div>
                    <div className="new-headers-container">
                        {headersData.map((header) => (
                            <div key={header.id} className="new-header-container">

                                <div className={`new-header ${header.id === activeHeader ? 'new-active' : ''}`} onClick={() => toggleHeader(header.id)}>
                                    <span className={`new-title ${header.id === activeHeader ? 'new-active-title' : ''}`}>
                                        {header.title}
                                    </span>
                                    <div className="new-radio-icon">
                                        <img src={Cherron} alt="Partner 1" style={{ color: 'white' }} />
                                    </div>
                                </div>

                                {header.id === activeHeader && (
                                    <div className={`new-content ${header.id === activeHeader ? 'new-active' : ''}`}>
                                        <p className="new-content-text">{header.content}</p>
                                    </div>
                                )}

                                {header.id === activeHeader && (
                                    <div className={`new-header-active-color ${header.id === activeHeader ? 'new-active' : ''}`}></div>
                                )}

                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* Current Partnership Component */}


            <div className="ourachivements">
                <div className="current-partnership-container">
                    <div className="partnerships-subtitle">
                        <h2 className='partnerships-subtitledd'>Current Partnerships:</h2>
                        <div>Making a Difference Together</div>
                    </div>
                    <p>
                        Farmsell takes pride in its collaborations with esteemed partners, each contributing to our shared mission of transforming agriculture and driving positive change. Here are three impactful examples of our current partnerships.
                    </p>

                </div>
                <div className="our-containers">
                    <div className="item">
                        <div className="icon">
                            <img src={image3} alt="Partner 1" style={{ maxWidth: '85%', height: 'auto' }} />
                        </div>
                    </div>
                    <div className="item">
                        <div className="icon">
                            <img src={image6} alt="Partner 2" style={{ maxWidth: '85%', height: 'auto' }} />
                        </div>
                    </div>
                    <div className="item">
                        <div className="icon">
                            <img src={image8} alt="Partner 3" style={{ maxWidth: '85%', height: 'auto' }} />
                        </div>
                    </div>

                    <div className="item">
                        <div className="icon">
                            <img src={image9} alt="Partner 4" style={{ maxWidth: '85%', height: 'auto', marginLeft: '-9%', marginTop: '-24%' }} />
                        </div>
                    </div>
                    <div className="item">
                        <div className="icon">
                            <img src={image10} alt="Partner 5" style={{ maxWidth: '85%', height: 'auto', marginTop: '-24%'  }} />
                        </div>
                    </div>
                    <div className="item">
                        <div className="icon">
                            <img src={image7} alt="Partner 6" style={{ maxWidth: '85%', height: 'auto', marginTop: '-47%' , marginLeft: '41%'  }} />
                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
};

export default StrategicComponent;

