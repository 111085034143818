import React, { useState, useEffect } from 'react';

import '../../assets/styles/communityHero.css'
import HeroImgae from '../../assets/images/9054851_Mesa de trabajo 1 1.svg';


const Hero = () => {

  return (

      <div id='hero-community'>
        <div id='hero-content'>


          <div id='hero-image'>
            <img src={HeroImgae} alt='Hero' id='hero-images' />
          </div>
          <div id='hero-texts'>
          <div className='hero-heading'>  <h2 id='hero-h'>
              Farming Together, <span className='bg-text'>Growing Strong</span>: Farmsell's Commitment to Community <span className='bg-text'>Engagement</span> in Agriculture
            </h2></div>
          <div className='hero-pwarp'>  <p id='hero-p'>Cultivating Connections Beyond Transactions: Join Farmsell’s Thriving Community for Shared Insights, Growth, and Mutual Success</p></div>
          </div>
        </div>
      </div>
   
  );
};
export default Hero
