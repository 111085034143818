import React, { useState } from 'react';
import '../../assets/styles/tailoredbenefits.css';
import ArrowUp from '../../assets/images/arrowup.svg';
import ArrowDown from '../../assets/images/arrowdown.svg';
import SmallHolder from '../../assets/images/tailored/smallholder.png';
import AgroBased from '../../assets/images/tailored/agrobased.png';
import HotelandRestorant from '../../assets/images/tailored/hotelandrestorant.png';
import Supermarkets from '../../assets/images/tailored/supermarkets.png';
import AgroInputs from '../../assets/images/tailored/agroinput.png';


const TailoredBenefits = () => {
  const [openSection, setOpenSection] = useState(0);

  const toggleSection = (index) => {
    setOpenSection(openSection === index ? index : index);
  };

  const sections = [
    {
      title: 'Smallholder Farmers',
      content: 'Empowering Your Harvest, Empowering Your Life Smallholder farmers, with Farmsell, your journey takes a transformative turn. Gain direct access to both buyers for your farm produce and reputable agri-input enterprises offering high-quality tools and inputs. Bid farewell to exploitation and market uncertainties as Farmsell becomes your reliable companion, ensuring your produce reaches the right buyers and your farm is equipped with the best tools for success.',
      img: SmallHolder
    },
    {
      title: 'Agro-Input Enterprises',
      content: 'Connecting Quality Inputs to the Fields that Need Them Agro-input enterprises, Farmsell is your direct gateway to farmers seeking top-notch tools and inputs. Showcase your high-quality farm inputs and tools to a wide audience of farmers, ensuring your products reach those who truly need them. Simplify your supply chain and witness your products seamlessly connecting with the farms that can benefit the most.',
      img: AgroInputs
    },
    {
      title: 'Hotels and Restaurant Chains',
      content: 'Feeding Success: Direct Access to Quality Farm Produce The hospitality industry (hotels or Restaurant), Farmsell is your key to sourcing the freshest and highest-quality farm produce directly from farmers. Ensure your customers are served with the best by connecting with farmers who can supply the great farming produce you require. Elevate your offerings and contribute to the success of your institution with Farmsell.',
      img: HotelandRestorant
    },
    {
      title: 'Supermarkets and Groceries',
      content: 'Elevate Your Shelves with Freshness In the retail industry, Farmsell is your gateway to securing a constant supply of the freshest and highest-quality farm produce directly from local farmers. Guarantee that your customers have access to the best by establishing direct connections with farmers who can consistently provide top-notch agricultural products. Enhance your product offerings and contribute to the success of your store by partnering with Farmsell.',
      img: Supermarkets
    },
    {
      title: 'Agro-Based Industries',
      content: 'Efficiency with Direct Access to Quality Raw Materials The Agro-Based industries, your efficiency journey begins with Farmsell. Gain direct access to high-quality raw materials sourced directly from farmers. Ensure a streamlined supply chain that meets your quality standards, as Farmsell becomes your strategic partner in connecting you with farmers who provide the raw materials essential for your production processes.',
      img: AgroBased
    },
  ];

  return (
    <div className="tailored-benefits">
      <div className='header'>
        <h2>Tailored <span className='colored'>Benefits</span> for Every <span className='colored'>Stakeholder</span> <br /></h2>
          <h3>Unlocking Prosperity <span className='mobile-none'> Across the Agricultural Spectrum: <br /> Farmsell's Tailored Benefits for You </span></h3>
        
        <div className='desc-text'>Discover the tailored benefits Farmsell brings to your doorstep, revolutionizing the way you engage in agricultural transactions. Dive into a world where every stakeholder, from smallholder farmers to agri-input enterprises, institutions, and factories, experiences direct benefits that redefine efficiency and success.
        </div>

      </div>
      <div className='content'>
        <div>
          {sections.map((section, index) => (
            <div key={index} className='element'>
              <div
                className="accordion-toggle"
                onClick={() => toggleSection(index)}
                aria-expanded={openSection === index}
              >
                {section.title}
                {openSection === index ? (
                  <img className='icon' src={ArrowUp} alt='arrowup' />
                ) : (
                  <img className='icon' src={ArrowDown} alt='arrowdown' />
                )}
              </div>
              <div
                className={`accordion-content ${openSection === index ? 'show' : ''
                  }`}
              >
                <div>
                  <p>{section.content}</p>
                  <div className='phone-image'>
                    <img
                      src={section.img}
                      alt={`Visual aid for ${section.title}`}
                      className={`accordion-image ${openSection === index ? 'show' : 'hide'
                        }`}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className='destop-image'>
          <img src={sections[openSection].img} alt='imageaid' />
          {/* <img src={sections[openSection].img} alt='imageaid' /> */}
        </div>
      </div>

    </div>
  );
};

export default TailoredBenefits;