
import React from 'react';
import Footer from '../Footer';
import Hero from './Hero';

import Connect from '../Connect/Connect';
import Engagements from '../Engagements/Engagements';
import Forums from '../Forums/Forums';
import Webinars from '../Webinars/Webinars';
import Farmsell from '../Farmsell/Farmsell';
import Seminars from '../Seminars/Seminars';
import TradeShows from '../TradeShows/TradeShows';
import Community from '../Community/Community';
import CommunityHeader from './CommunityHeader';
// styles
import '../../assets/styles/Comunity.css'
export const CommunityHome = () => {
  return (
    <div className='commtHeader' style={{ overflowX: 'hidden'}}>
      <CommunityHeader/>
      <Hero />
      <Connect />
      <Engagements />
      <Forums />
      <Webinars />
      <Farmsell />
      <Seminars />
      <TradeShows />
      <Community />
      <Footer />
    </div>
  );
};
