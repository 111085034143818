import React from 'react'
import '../../assets/styles/Connect.css'
import connectImage from '../../assets/images/12749704_multiculture1 1.svg'
export default function Connect() {
  return (
    <div className='connect-c'>


<div className='wrapper'>

 <div className='connect-text'>
    <h2 className='connect-h'>Connect, Learn, Thrive</h2>
    <p className='connect-p'> Be a Part of Farmsell's Agricultural Community. <br/>Join us Today!</p>
    <button className='btn-connect'>Join Community</button>
 </div>

 <img className='connect-images' src={connectImage} alt='connect' />
</div>
    </div>
  )
}
