import React from 'react';
import '../../../assets/styles/about-us/ElevateYour.css';
import YourImage from '../../../assets/images/aboutus/Image(1).png';

const handleButtonClick = () => {
    
    window.location.href = '/join-farmsell';
  };

const ElevateYour = () => {
    return (
        <div className="elevate-your-container">
            <div className="elevate-your-content">
                <div className="elevate-your-text">
                    <h2 className='Elevate'>Elevate Your Agribusiness</h2>
                    <p>Join Farmsell Now and Harvest the Power of Efficient B2B Transactions!</p>
                    <button className='JoinNows' onClick={handleButtonClick}>
      Join Now, It's Free
    </button>
                </div>
                <div className="elevate-your-image">
                    <img src={YourImage} alt="Your-image" />
                </div>
            </div>
        </div>
    );
};

export default ElevateYour;
