import React from 'react'
import '../../assets/styles/bannerGet.css';
import BannerImage from '../../assets/images/phoneBanner.svg.png';

import {useNavigate} from 'react-router';
import { useEffect } from 'react';
import navigateToTopPage from '../../shared/navigateTop';

const BannerGetStarted = () => {

  const navigate = useNavigate ();


  const joinFarmsellRedirect = () => {

    
    navigate ('/join-farmsell');
  };

  useEffect(()=>{

    navigateToTopPage();
  }, [])

  
  return (
    <div>
        <div className='banner'>

            <div className='left-ban'>
                <h2 className='title-top'>Get started</h2>
                <h2 className='banner-title'>
                with <span className='banner-span'>Farmsell </span> today</h2>

                <button className='banner-button' onClick={()=>joinFarmsellRedirect()}>Join Now, Its Free</button>
            </div>

            <div className='right-ban'>
                <img className='image-banner' src={BannerImage}/>
            </div>

        </div>

    </div>
  )
}

export default BannerGetStarted;