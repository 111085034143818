import React from 'react';
import '../../assets/styles/HeroSection1.css';
import myImage from '../../assets/images/myImage.png'

const HeroSection = () => {
  return (
    <div className="container-HeroSection">
      <div className="textContainerhere">
        <h2 className='Cultivaed'>Cultivating Prosperity</h2>
        <h3 className='Premier'>
          Farmsell, Your <span className='Prem'>Premier Digital</span> Hub for Revolutionary <span className='B2B'>B2B Agricultural Transactions</span>
        </h3>
        <p className='Reward'>
          Sow Success, Reap Rewards: Join Farmsell in Streamlining Your Agricultural Journey with Innovation, Efficiency, and Integrity
        </p>
      </div>
      <div className="imagesContainer">
        <img src={myImage} alt="Sample" className='samplse' />
      </div>
    </div>
  );
};

export default HeroSection;