import React from 'react';

const GradientText = ({ text, gradient }) => {
  const gradientStyle = {
    backgroundImage: gradient,
    WebkitTextFillColor: 'transparent',
    WebkitBackgroundClip: 'text',
    display: 'inline-block',
    textDecoration: 'underline ',
  };

  return <span style={gradientStyle}>{text}</span>;
};

export default GradientText;

