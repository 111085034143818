import React from "react";
import BannerImg from "../../assets/images/contactus/contact-img.svg";

const ContactBanner = () =>{
    return(
        <div className="contact-banner">
            <div className="cont-container">
                <div className="text-cont">
                    <h2>Connect with our <span>team</span> for quick help</h2>
                    <div className="desc-text">
                    Whether you're interested in learning more about us or need help with any issue, we're just a message away. Contact us today and let's get the conversation started.
                    </div>
                </div>
                <div className="img-cont">
                    <img src={BannerImg} alt="Bannerimage" />
                </div>
            </div>

        </div>

    );
};

export default ContactBanner;