// CombinedComponent.js

import React from 'react';
import '../../assets/styles/MeetTheVisionary.css';
import visionaryImage from '../../assets/images/aboutus/ben.png';

const MeetTheVisionary = () => {
    return (
        <div className="combined-container">
            {/* Founding Story Section */}
            <div className="founder-container">
                <h2 className="founder-header">
                    <span className='Founding'>Founding</span> Story
                </h2>
                <h3 className='Cultivat'>
                    Cultivating <span className='Innovation'>Innovation</span> in Agriculture
                </h3>
                <p>
                In 2022, Farmsell sprouted as a response to the glaring challenges faced by smallholder farmers, agri-input enterprises, and institutions in the agricultural sector. Conceived from the soil of necessity, Farmsell was validated and meticulously piloted throughout 2023, culminating in its official launch in 2024. The agricultural landscape was burdened by the age-old issues of limited access to reliable markets, exploitation by middlemen, and the inconvenience of physical market travels. Smallholder farmers faced the harsh reality of post-harvest losses and income constraints while buyers of Agriproducts were harshly exploited by the middlemen.
                </p>
                <p>
                Driven by a passion for agriculture and a strong desire of alleviating these challenges, Farmsell emerged as the solution. With over 10,000 downloads and 5,000 active users, Farmsell leverages technology to revolutionize agriculture, offering a reliable and efficient digital marketplace for agricultural products. The platform acts as a lifeline for farmers, empowering them to connect directly with buyers and sell their produce hassle-free. Farmsell not only reduces post-harvest losses but also significantly increases the income of smallholder farmers, transforming the agricultural landscape.
                </p>
            </div>

            {/* Meet the Visionary Section */}
            <div className="visionary-container">
                <div className="image-contaiiner">
                    <img src={visionaryImage} alt="Visionary" />
                    <div className="overlay">
                        <h3>Dr. Benie K. Mooya</h3>
                        <p>The Visionary Founder of Farmsell</p>
                    </div>
                </div>
                <div className="text-contaiiner">
                    <h2 className='farmes'>Meet the <span className='vissionery'>Visionary</span> Behind Farmsell</h2>
                    <p>
                        Dr. Benard K. Mooya, the visionary founder of Farmsell, holds a doctorate in Digital Business, Innovation, and Management. With over 20 years of working experience, Dr. Mooya has managed a vast portfolio of agricultural-related projects and community development initiatives. Formerly the country director at BRAC International, Living Water International, and Action Africa Help (AAH), Dr. Mooya has also collaborated with organizations such as GOAL, CRS, and AMREF.
                    </p>
                    <p>Born and raised in a remote village in Eastern Uganda, Dr. Mooya's journey reflects the struggles faced by millions of smallholder farmers. Inspired by his personal experiences and armed with a deep understanding of the agricultural sector, Dr. Mooya envisioned Farmsell as a beacon of change. The platform became his brainchild, addressing market inefficiencies and providing answers to critical questions: What if farmers could dictate when and at what price they sell? What if buyers had access to comprehensive market information? Farmsell is the revolutionary answer, offering a platform for seamless agricultural transactions.</p>
                    <p>
                        Follow Dr. Mooya on <a href="https://www.linkedin.com/comm/mynetwork/discovery-see-all?usecase=PEOPLE_FOLLOWS&followMember=bmooya&lipi=urn%3Ali%3Apage%3Ad_flagship3_pulse_read%3BPwUfNP%2FRTLihkCizYUMyQg%3D%3D" target="_blank" rel="noopener noreferrer">LinkedIn</a> to stay updated on the newest insights and engage in discussions about sustainable farming and markets for smallholder farmers.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default MeetTheVisionary;
